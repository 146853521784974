import React, { useEffect, useState } from 'react'
import logo from '../../../../icons/logo.png'
import { useNavigate, useParams } from 'react-router-dom'
// import data from '../../../../demoData/data.json'
import CustomModal from '../../../../base-components/CustomModal'
import Popup from '../../../../base-components/Popup'
import master from '../../../../icons/png-clipart-logo-mastercard-pentagram-flat-design-brand-mastercard-text-trademark.png'
import visa from '../../../../icons/png-clipart-logo-credit-card-visa-debit-card-credit-card-blue-text.png'
import paid1 from '../../../../icons/paid1.png'
import paid2 from '../../../../icons/paid2.png'
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material'

const theme = createTheme({
    palette: {
      primary: {
        main: '#033b51'
      },
    },
  });

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Voucher() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [voucher , setVoucher] = useState()
    const [member , setMember] = useState()
    const [family_members , setFamilyMembers] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isExtendModalOpen, setIsExtendModalOpen] = useState(false)
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [alertType , setAlertType] = useState('success')
    
    // const filteredData = data.invoices.filter(invoice => invoice.id === id);
    // console.log(data);
    
    const [message , setMessage] = useState('')
    const [cardName , setCardName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvc, setCvc] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cvcError, setCvcError] = useState('');
    const [member_package_amount , setMemberPackageAmount] = useState(0)
    const [packages , setPackages] = useState([])
    const [loading , setLoading] = useState(true)
    const [role , setRole] = useState('')
    const [location_id , setLocationID] = useState('')
    const [company_id , setCompanyID] = useState('')
    const [member_id , setMemberID] = useState('')
    // const [price , setPrice] = useState([])

    useEffect(()=> {
        const sessionStr = sessionStorage.getItem('user-info');
        if(sessionStr){
          const session = JSON.parse(sessionStr);
          setRole(session.user.role)
          setCompanyID(session.user.company_id)
          setLocationID(session.user.location_id)
          setMemberID(session.user.member_id)
        }
         else {
          navigate('/login');
        }
    },[])

    useEffect(()=> {

    // DO NOT delete this code its important for paypro integration

        // const url = window.location.href;
        // const urlParams = new URLSearchParams(new URL(url).search);
        // const ordId = urlParams.get('ordId');
        // console.log(ordId);
        
        // if(ordId) {
        //     handleChangeVoucherStatus(ordId)
        // }
        if(role) {
            getVoucherById()
            getAllPackages()
        }
         // const status = urlParams.get('status');
        // const msg = urlParams.get('msg');
    },[role])

    function getVoucherById () {
        fetch(BASE_URL + `voucher/getVoucherById/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setVoucher(data.voucher)
            getMemberByID(data.voucher.member_id)
        })
        .catch((error) => console.log(error));
    }

    function getAllPackages () {
        fetch(BASE_URL + `package/getPackages`)
        .then((response) => response.json())
        .then((data) => {
            setPackages(data.packages)
        })
        .catch((error) => {
          console.log(error)
      });
    }
   
    function getMemberByID (id) {
        fetch(BASE_URL + `members/get-member/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setMember(data.member)
            getMemberPackage(data.member.package_id)
            // getFamilyMembers(data.member._id)
        })
        .catch((error) => console.log(error));
    }

    function getMemberPackage (id) {
        fetch(BASE_URL + `package/getPackageById/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setMemberPackageAmount(data.packages.price)
            console.log(data.packages.price);
            setLoading(false)
        })
        .catch((error) => console.log(error));
    }

    function getFamilyMembers (member_id) {
      fetch(BASE_URL + `familyMembers/getAllFamilyMembersByMemberId/${member_id}`)
      .then((response) => response.json())
      .then((data) => {
          setFamilyMembers(data.familyMembers)
        //   data.familyMembers.map((member) => {
        //     getFamilyMemberPackage(member.package_id)
        //   })
      })
      .catch((error) => {
        console.log(error)
    });
  }

    const formatCardNumber = (value) => {
        // Remove all non-digit characters
        const cleaned = value.replace(/\D+/g, '');
        
        // Insert a dash after every 4 digits, but don't add one at the end
        const formatted = cleaned.match(/.{1,4}/g)?.join('-') || '';
        
        return formatted;
      };
    
      const handleNumberInputChange = (e) => {
        let { value } = e.target;
        const cursorPosition = e.target.selectionStart; // Get cursor position
    
        // Save the position of the cursor in the raw string
        const rawValue = value.replace(/-/g, ''); // Remove dashes to get the raw value
    
        // Format the number
        value = formatCardNumber(rawValue);
        
        // Update state with formatted value
        setCardNumber(value);
    
        // Adjust cursor position after formatting
        const newCursorPosition = cursorPosition + (value.split('-').length - 1);
        e.target.setSelectionRange(newCursorPosition, newCursorPosition);
      };

      const handlePayVoucher = async () => {

    // Do NOT DELETE this commented code , its imortant for pay pro integration

        const data = {
            invoice_no: voucher?.invoice_no,
        }
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        try {
            const response = await fetch(BASE_URL + `voucher/init_payment`, requestOptions);

            console.log('Response status:', response.status);
            if (!response.ok) {
                console.error('Response not ok:', response);                
            } else {
                const responseData = await response.json();
                console.log('Response data:', responseData);
                console.log(responseData.data[1].Click2Pay);
                if (responseData.data[1].Click2Pay) {
                    const url = responseData.data[1].Click2Pay + `&callback_url=http://103.141.229.97/voucher/${voucher?._id}`;
                    window.open(url, '_blank');
                }
            }
        } catch (error) {
            console.error('Caught error:', error);
        }      
    }


    // const  handleChangeVoucherStatus = async (order_id) => {
    //     const data = {
    //         paypro_id: order_id,
    //     }
    //     const requestOptions = {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(data),
    //     };

    //     try {
    //         const response = await fetch(BASE_URL + `voucher/updatePaymentStatus`, requestOptions);

    //         console.log('Response status:', response.status);
    //         if (!response.ok) {
    //             console.error('Response not ok:', response);                
    //         } else {
    //             const responseData = await response.json();
    //             console.log('Response data:', responseData);
    //             getVoucherById()
    //             setIsPopupOpen(true)
    //             setTimeout(()=> {
    //                 setIsPopupOpen(false)
    //             }, 3000)
    //         }
    //     } catch (error) {
    //         console.error('Caught error:', error);
    //     }      
    // }

    
    const  handleExtendDueDate = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await fetch(BASE_URL + `voucher/extendDueDate/${voucher._id}`, requestOptions);

            console.log('Response status:', response.status);
            if (!response.ok) {
                console.error('Response not ok:', response);  
                const responseData = await response.json();
                setAlertType('error')
                setMessage(responseData.message)
                setIsExtendModalOpen(false)
                setIsPopupOpen(true)
                setTimeout(()=> {
                    setIsPopupOpen(false)
                }, 3000) 
            } else {
                const responseData = await response.json();
                console.log('Response data:', responseData);
                setLoading(true)
                setAlertType('success')
                setMessage(responseData.message)
                getVoucherById()
                setIsExtendModalOpen(false)
                setIsPopupOpen(true)
                setTimeout(()=> {
                    setIsPopupOpen(false)
                }, 3000)
            }
        } catch (error) {
            console.error('Caught error:', error);
        }      
    }

    

    const  handleChangeVoucherStatus = async () => {
        const data = {
            id: voucher._id,
        }
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        try {
            const response = await fetch(BASE_URL + `voucher/updatePaymentStatus`, requestOptions);

            console.log('Response status:', response.status);
            if (!response.ok) {
                console.error('Response not ok:', response);  
                const responseData = await response.json();
                setAlertType('error')
                setMessage(responseData.message)
                setIsExtendModalOpen(false)
                setIsPopupOpen(true)
                setTimeout(()=> {
                    setIsPopupOpen(false)
                }, 3000)              
            } else {
                const responseData = await response.json();
                console.log('Response data:', responseData);
                setLoading(true)
                setAlertType('success')
                setMessage(responseData.message)
                getVoucherById()
                setIsModalOpen(false)
                setIsPopupOpen(true)
                setTimeout(()=> {
                    setIsPopupOpen(false)
                }, 3000)
            }
        } catch (error) {
            console.error('Caught error:', error);
        }      
    }

      const formatExpiryDate = (value) => {
        // Remove all non-digit characters
        const cleaned = value.replace(/\D+/g, '');
    
        // Format as MM/YY, but don't exceed 5 characters
        if (cleaned.length <= 2) {
          return cleaned;
        }
        return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
      };
    
      const validateExpiryDate = (value) => {
        // Check if the expiry date is in MM/YY format
        if (!/^\d{2}\/\d{2}$/.test(value)) {
          return 'Expiry date must be in MM/YY format';
        }
    
        const [month, year] = value.split('/').map(Number);
        
        // Validate month (must be between 01 and 12)
        if (month < 1 || month > 12) {
          return 'Invalid month. Must be between 01 and 12.';
        }
    
        // Validate year (must not be in the past)
        const currentYear = new Date().getFullYear() % 100; // Get last 2 digits of current year
        const currentMonth = new Date().getMonth() + 1; // Get current month (0-based, so +1)
        
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
          return 'Expiry date cannot be in the past.';
        }
    
        return '';
      };
    
      const handleExpiryInputChange = (e) => {
        const { value } = e.target;
    
        // Format the value as MM/YY
        const formattedValue = formatExpiryDate(value);
    
        // Validate the formatted value
        const validationError = validateExpiryDate(formattedValue);
    
        // Update state
        setExpiryDate(formattedValue);
        setExpiryError(validationError);
      };
    
      const handleCvcInputChange = (e) => {
        let { value } = e.target;
    
        // Ensure that only numbers are entered, and restrict to 3 digits max
        if (/^\d{0,3}$/.test(value)) {
          setCvc(value);
          setCvcError(''); // Clear any previous errors
        } else {
          setCvcError('CVC must be a 3-digit number.');
        }
      };
    
   const handleSubmit = (e) => {
        e.preventDefault()
        setIsModalOpen(false)
        resetFields()
        setIsPopupOpen(true)
        setTimeout(() => {
            setIsPopupOpen(false)
        } , 3000)
   }

   const resetFields = () => {
        setCardNumber('')
        setExpiryDate('')
        setCvc('')
        setCardName('')
   }

  return (
    <>
    {
     loading ? (
        <ThemeProvider theme={theme}><div className='flex justify-center items-center mt-20'><CircularProgress/></div></ThemeProvider>
     ) : (
        <div className='bg-slate-50 container mx-auto p-10'>
            <div className='border-b-2 mb-10 flex justify-between items-center'>
                <img src={logo} alt='' className='text-center p-2 w-36'></img>
                {
                voucher?.is_paid === false ? (
                    <div>
                        {
                            role !== 'User' ? (
                                <div>
                                    {
                                        new Date(voucher?.due_date).toISOString().split('T')[0] < new Date().toISOString().split('T')[0] &&
                                        <span className='bg-blue-700 p-2 px-4 mx-2 text-white font-bold rounded-full hover:cursor-pointer'
                                        onClick={()=> setIsExtendModalOpen(true)}
                                        >Extend Due Date</span>
                                    }
                                    <span className='bg-green-700 p-2 px-4 text-white font-bold rounded-full hover:cursor-pointer'
                                    onClick={()=> setIsModalOpen(true)}
                                    >Pay Invoice</span>
                                </div>
                            ) : null
                        }
                        
                    </div>
                ): (
                    voucher?.is_paid === true ? (
                        <div className=''>
                            <img src={paid1} alt='' />
                        </div>
                    ):
                    null
                )
                }
            </div>
            <div className='grid grid-cols-3 gap-5'>
                <div className=''>
                    <p><strong>From:</strong>{member.company.company_name}</p>
                    <p><strong>Address:</strong>{member.location.address}</p>
                    <p><strong>Email:</strong>{member.location.email}</p>
                    <p><strong>Phone No:</strong>{member.location.phone_number}</p>
                </div>
                <div className=''>
                    <p><strong>To:</strong>{member?.name}</p>
                    <p><strong>Address: </strong>{member?.address}</p>
                    {/* <p><strong>CNIC: </strong>{member?.doc_number}</p> */}
                    <p><strong>Phone No: </strong>{member?.phone}</p>
                    <p><strong>Email: </strong>{member?.email}</p>
                </div>
                <div className=''>
                    <p><strong>Invoice Date: </strong>{new Date(voucher?.voucher_date).toISOString().split('T')[0]}</p>
                    <p><strong>Inoice No:</strong>{voucher?.invoice_no}</p>
                    <p><strong>Due Date: </strong>{new Date(voucher?.due_date).toISOString().split('T')[0]}</p>
                    <p><strong>Subscription Valid Till: </strong>{new Date(voucher?.subscription?.end_date).toISOString().split('T')[0]}</p>
                </div>
            </div>
            <div className='mt-10 mb-2 font-bold text-xl'>Charges:</div>
            <table className='w-full text-left '>
                <tbody className='font-medium'>
                    <tr className='border-b-2' >
                        <td className='p-2'>1</td>
                        <td>Membership Fee</td>
                        <td>{voucher?.membership_fee}</td>
                    </tr>
                    {
                        voucher?.registration_fee > 0 && (
                            <tr className='border-b-2' >
                                <td className='p-2'>2</td>
                                <td>Registration Fee</td>
                                <td>{voucher?.registration_fee}</td>
                            </tr>
                        )
                    }
                    
                    {
                        voucher?.trainer_fee > 0 && (
                            <tr className='border-b-2' >
                                <td className='p-2'>3</td>
                                <td>Trainer Fee</td>
                                <td>{voucher?.trainer_fee}</td>
                            </tr>
                        )
                    }
                    
                </tbody>
            </table>
            <div className='flex justify-between mt-10'>
                <div className='flex items-center'>
                    <h1 className='font-bold p-2'>Payment Methods:</h1>
                    <p>Cash</p>
                </div>
                <div>
                    {
                        voucher?.is_paid === false ? (
                            <h1 className='p-2 border-b-2'>Subscription Valid Till {new Date(voucher?.due_date).toISOString().split('T')[0]}</h1>
                        ): null
                    }
                    <table className='mt-5 w-full'>
                        <tr className='border-b-2'>
                            <td className='pb-2 font-bold'>Subtotal:</td>
                            <td className='pb-2'>{voucher?.membership_fee + voucher?.registration_fee + voucher?.trainer_fee}</td>
                        </tr>
                        <tr>
                            <td className='font-bold pt-5'>Total:</td>
                            <td className='pt-5'>{voucher?.membership_fee + voucher?.registration_fee  + voucher?.trainer_fee}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
     )
    }
    
    {/* <CustomModal isModal={isModalOpen} handleModal={() => setIsModalOpen(false)} size={'2xl'}>
        <form onSubmit={handleSubmit} className='m-10 text-slate-400'>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label className=''>Name on Card:</label>
                    <img alt='' src={master} className='h-5 float-right'/>
                    <img alt='' src={visa} className='h-5 float-right'/>
                    <input type='text' name='name' placeholder='Name' 
                    value={cardName}
                    onChange={(e)=>setCardName(e.target.value)}
                     className='p-2 border w-full rounded-lg'/>
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label className=''>Card Number:</label>
                    <input type='text' name='cardNumber' placeholder='Card Number'
                     value={cardNumber}
                     onChange={handleNumberInputChange}
                     maxLength="19"
                     className='p-2 border w-full rounded-lg'/>
                </div>
                <div className='w-full'>
                    <label className=''>Expiry Date:</label>
                    <input type='text' name='expiry' placeholder='Expiry Date (MM/YY)'
                        value={expiryDate}
                        onChange={handleExpiryInputChange}
                        className='p-2 border w-full rounded-lg'
                        maxLength="5"/>
                        {expiryError && <p className="text-red-500">{expiryError}</p>}
                </div>
                <div className='w-full'>
                    <label className=''>CVC:</label>
                    <input type='text' name='cvc' placeholder='CVC'
                    value={cvc}
                    onChange={handleCvcInputChange} 
                    className='p-2 border w-full rounded-lg'
                    maxLength='3'/>
                    {cvcError && <p className="text-red-500">{cvcError}</p>}
                </div>
            </div>
            <div>
                <button className='py-2 m-5 px-10 float-right bg-[#C30000] text-white rounded-full hover:bg-[#db0000]'>Pay</button>
            </div>
        </form>
    </CustomModal> */}

    <CustomModal isModal={isModalOpen} handleModal={() => setIsModalOpen(false)} size={'xl'}>
        <div className='m-5 p-2 flex justify-center gap-10 text-slate-600 border border-slate-500 rounded-lg font-bold'>
            <div className='flex justify-start items-center gap-2'>
                <h1>Name:</h1>
                <p className='font-semibold	'>{member?.name}</p>
            </div>
            <div className='flex justify-start items-center gap-2'>
                <h1>Amount:</h1>
                <p className='font-semibold	'>{voucher?.membership_fee + voucher?.registration_fee + voucher?.trainer_fee} </p>
            </div>
        </div>
        
        <div className='m-5 text-center'>
            <h2 className="text-xl font-medium text-slate-600">Pay Voucher?</h2>
            <p className="text-slate-500 mt-3">Are you sure you want to pay this voucher?</p>
            <div className='flex justify-center items-center gap-3 my-5'>
                <button className='bg-green-600 p-2 px-8 border border-slate-600 rounded-lg text-white font-medium hover:bg-green-700' onClick={handleChangeVoucherStatus}>Yes</button>
                <button className='bg-red-600 p-2 px-8 border border-slate-600 rounded-lg text-white font-medium hover:bg-red-700' onClick={()=>isModalOpen(false)}>No</button>
            </div>
           
        </div>
    </CustomModal>

    <CustomModal isModal={isExtendModalOpen} handleModal={() => setIsExtendModalOpen(false)} size={'xl'}>
        <h2 className="m-5 text-center text-xl font-medium text-slate-600">Extend Voucher Due Date?</h2>
        <div className='mx-10 p-2 text-slate-600 border border-slate-500 rounded-lg font-bold'>
            <div className='flex justify-between gap-10'>
                <div className='flex justify-start items-center gap-2'>
                    <h1>Due Date:</h1>
                    <p className='font-semibold	'>{
                        voucher?.due_date
                        ? new Date(voucher.due_date).toISOString().split('T')[0]
                        : 'Not available'
                }</p>
                </div>
                <div className='flex justify-start items-center gap-2'>
                    <h1>Extended Due Date:</h1>
                    <p className='font-semibold	'>{voucher?.due_date 
                        ? new Date(new Date(voucher.due_date).setDate(new Date(voucher.due_date).getDate() + 5))
                            .toISOString()
                            .split('T')[0]
                        : 'Not available'}
                    </p>
                </div>
            </div>
            <div className='flex py-2 justify-start items-center gap-2'>
                <h1>Name:</h1>
                <p className='font-semibold	'>{member?.name}</p>
            </div>
        </div>
        
        <div className='m-5 text-center'>
            <p className="text-slate-500 mt-3">Are you sure you want to extend this voucher due date?</p>
            <div className='flex justify-center items-center gap-3 my-5'>
                <button className='bg-green-600 p-2 px-8 border border-slate-600 rounded-lg text-white font-medium hover:bg-green-700' onClick={handleExtendDueDate}>Yes</button>
                <button className='bg-red-600 p-2 px-8 border border-slate-600 rounded-lg text-white font-medium hover:bg-red-700' onClick={()=>setIsExtendModalOpen(false)}>No</button>
            </div>
           
        </div>
    </CustomModal>
    
    {
        isPopupOpen ? (
            <Popup alertType={alertType} message={message} onClose={() => setIsPopupOpen(false)}/>
        ) : null
    }
    </>
  )
}

export default Voucher