import { faCancel, faCheck, faCircleCheck, faClose, faCross, faEye, faEyeSlash, faPen, faTimes, faTrash, faWarning, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import { Box, CircularProgress, createTheme, MenuItem, Select, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomModal from '../../../base-components/CustomModal';
import { Formik, useFormik } from 'formik';
import { locationSchema } from '../../../schemas';
import Popup from '../../../base-components/Popup';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const theme = createTheme({
    palette: {
      primary: {
        main: '#033b51'
      },
    },
  });
function Locations() {
    const navigate = useNavigate()
    const [locations , setLocations] = useState([])
    const [location , setLocation] = useState({})
    const [locationToDelete , setLocationToDelete] = useState('')
    const [locationToUpdate , setLocationToUpdate] = useState('')
    const [isAddModalOpen , setIsAddModalOpen] = useState(false)
    const [isViewModalOpen , setIsViewModalOpen] = useState(false)
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [formData , setFormData] = useState({})
    const [formErrors , setFormErrors] = useState({})
    const [page , setPage ] = useState(0)
    const [countries , setCountries] = useState([])
    const [selectedCountry , setSelectedCountry] = useState('')
    const [selectedState , setSelectedState] = useState('')
    const [selectedCity , setSelectedCity] = useState('')
    const [selectedCountryIsoCode , setSelectedCountryIsoCode] = useState('')
    const [states , setStates] = useState([])
    const [cities , setCities] = useState([])
    const [selectedStateCode , setSelectedStateCode] = useState('')
    const [showPassword , setShowPassword] = useState(false)
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [company_id , setCompanyID] = useState('')
    const [location_id , setLocationID] = useState('')
    const [role , setRole] = useState('')
    const [loading , setLoading] = useState(false)
    useEffect(() => {
        const sessionStr = sessionStorage.getItem('user-info');
        if (sessionStr) {
            const session = JSON.parse(sessionStr);
            setRole(session.user.role);
            setCompanyID(session.user.company_id);
            setLocationID(session.user.location_id);
        } else {
            navigate('/login');
        }
    }, []);
    
    useEffect(() => {
        if(role){
            setLoading(true)
            getLocations();
            getCountries();
            setTimeout(()=> {
                setLoading(false)
            },1000)
        }
            
    }, [role]);

    async function getCountries () {
        await fetch(BASE_URL + `country/getCountries`)
        .then((response) => response.json())
        .then((data) => {
            setCountries(data)
            
        })
        .catch((error) => console.log(error));
    
    }

    const handleSelectCountry = (value) => {
        setSelectedCountryIsoCode(value);
        const con = countries.filter(country => country.isoCode === value)
        const countryName = con.length > 0 ? con[0].name : null;
        setSelectedCountry(countryName)
        getStates(value);
        getCities(value)
    }

     const getStates = async(value) => {
       await fetch(BASE_URL + `country/getStatesById/${value}`)
        .then((response) => response.json())
        .then((data) => {
            setStates(data)
            
        })
        .catch((error) => console.log(error));
    }

    const handleSelectState = (value) => {
        setSelectedStateCode(value);
        const con = states.filter(state => state.isoCode === value)
        const stateName = con.length > 0 ? con[0].name : null;
        setSelectedState(stateName)
    }
    
    const getCities = async (value) => {
        await fetch(BASE_URL + `country/getCitiesById/${value}`)
        .then((response) => response.json())
        .then((data) => {
            setCities(data)
        })
        .catch((error) => console.log(error));
    }
    


    async function getLocations () {
        let endpoint = '';
        console.log(role);
        if (role === 'SuperAdmin') {
            endpoint = 'locations/getLocations';
        } else if (role === 'Admin') {
            endpoint = `locations/getLocationsByCompanyID/${company_id}`;
        }
        
        await fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setLocations(data.locations)
            
        })
        .catch((error) => console.log(error));
    }

    async function getLocationByID (id) {
        await fetch(BASE_URL + `locations/getLocationById/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setLocation(data.location)
            setValues({
                name: data.location.name,
                email: data.location.email,
                password: data.location.password,
                phone_number: data.location.phone_number,
                description: data.location.description,
                address: data.location.address,
                country: data.location.country,
                state: data.location.state,
                city: data.location.city,
                postal_code: data.location.postal_code,
                opening_hours: data.location.opening_hours,
                closing_hours: data.location.closing_hours,
                company_id: data.location.company_id,
            });
        })
        .catch((error) => console.log(error));
    }
    
    const handleAddLocation = async (values, actions) => {

        values.company_id = company_id;

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            };
            console.log(company_id);
            
            try {
                const response = await fetch(BASE_URL + `locations/createLocation`, requestOptions);
    
                console.log('Response status:', response.status);
                if (!response.ok) {
                    console.error('Response not ok:', response);
                    setIsFailureAlert(true)
                    failureMessage('Failed to add Location')
                    // handleFailureOpen('Failed to Add Add');
                } else {
                    const responseData = await response.json();
                    console.log('Response data:', responseData);
                    setIsAddModalOpen(false)
                    actions.resetForm()
                    getLocations()
                    setIsSuccessAlert(true)
                    setSuccessMessage('Location added Successfully')
                    isPopupOpen(true)
                    setTimeout(() => {
                        setIsPopupOpen(false)
                    } , 5000)
                }
            } catch (error) {
                console.error('Caught error:', error);
                setIsFailureAlert(true) 
                setFailureMessage('Failed to add Location')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                } , 5000)
            } finally {
                // setLoading(false);
            }
    
    }

    const handleUpdateLocation = async (values, actions) => {
        values.company_id = company_id;
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };

        try {
            const response = await fetch(BASE_URL + `locations/updateLocation/${locationToUpdate}`, requestOptions);

            console.log('Response status:', response.status);
            if (!response.ok) {
                console.error('Response not ok:', response);
                setIsFailureAlert(true)
                setFailureMessage('Failed to Update Location')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            } else {
                const responseData = await response.json();
                console.log('Response data:', responseData);
                actions.resetForm()
                setIsUpdateModalOpen(false)
                getLocations()
                setIsSuccessAlert(true)
                setSuccessMessage('Location Updated Successfully')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            }
        } catch (error) {
            console.error('Caught error:', error);
            setIsFailureAlert(true)
            setFailureMessage('Failed to Update Location')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        } finally {
            // setLoading(false);
        }

}

    async function deleteLocation () {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        };
        await fetch(BASE_URL + `locations/deleteLocation/${locationToDelete}` , requestOptions)
        .then((response) => response.json())
        .then((data) => {
            getLocations()
            setIsDeleteModalOpen(false)
            setIsSuccessAlert(true)
            setSuccessMessage('Location deleted Successfully')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        })
        .catch((error) => {
            console.log(error)
            setIsFailureAlert(true)
            setFailureMessage('Failed to delete Location')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        });
    }


    function toggleAddModal() {
        setIsAddModalOpen(!isAddModalOpen)
        resetForm()
    }
    function toggleViewModal() {
        setIsViewModalOpen(!isViewModalOpen)
        resetForm()
    }

    function toggleUpdateModal() {
        setIsUpdateModalOpen(!isUpdateModalOpen)
        resetForm()
    }
    function toggleDeleteModal() {
        setIsDeleteModalOpen(!isDeleteModalOpen)
    }

    function showAddModal(id) {
        setIsAddModalOpen(true);
    }
    function showViewModal(id) {
        getLocationByID(id);
        setIsViewModalOpen(true);
    }
    function showUpdateModal(id) {

        getLocationByID(id);
        setLocationToUpdate(id);
        setIsUpdateModalOpen(true);
    }
    function showDeleteModal(id) {
        setLocationToDelete(id);
        setIsDeleteModalOpen(true);
    }
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm} = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone_number: '',
            description: '',
            password:'',
            address:'',    
            country: '',    
            state: '',
            city: '',
            postal_code: '',
            opening_hours: '',
            closing_hours: '',
            company_id: company_id ? company_id : '',
        },
        validationSchema: locationSchema(isUpdateModalOpen),
        onSubmit: (values, actions) => {
            if(isAddModalOpen){
                handleAddLocation(values, actions); // Call the external function
            } else if(isUpdateModalOpen) {
                handleUpdateLocation(values, actions);
            }
        },
    })

  return (
    <>
        <div className='flex items-center justify-between m-5'>
            <div className='font-bold text-2xl'>Locations</div>
            <button className='bg-[#022A3A] text-white font-bold p-2 rounded-lg ' onClick={showAddModal}>Add Location</button>
        </div>
        {
          loading ? <ThemeProvider theme={theme}><div className='flex justify-center items-center mt-20'><CircularProgress/></div></ThemeProvider>
          : (
            locations && locations.length !== 0 ? (     
                <Box className="border rounded-lg m-5 animate-fadeIn">
                    <Table>
                      <TableHead>
                          <TableRow >
                              <TableCell>Name</TableCell>
                              <TableCell>Address</TableCell>
                              <TableCell>Phone</TableCell>
                              <TableCell>Open Time</TableCell>
                              <TableCell>Close Time</TableCell>
                              <TableCell>Actions</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          locations.map((location , index) => (
                            <TableRow hover key={index}>
                              <TableCell>{location.name}</TableCell>
                              <TableCell>{location.address}</TableCell>
                              <TableCell>{location.phone_number}</TableCell>
                              <TableCell>{location.opening_hours}</TableCell>
                              <TableCell>{location.closing_hours}</TableCell>
                              <TableCell>
                                <div className='flex gap-2'>
                                  <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                                    showViewModal(location._id);
                                  }}>
                                      <FontAwesomeIcon icon={faEye}/> View 
                                  </div>
                                  <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                                    showUpdateModal(location._id);
                                  }}>
                                      <FontAwesomeIcon icon={faPen}/> Update 
                                  </div>
                                  <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-700'
                                  onClick={()=>{
                                    showDeleteModal(location._id);
                                  }}
                                  >
                                      <FontAwesomeIcon icon={faTrash}/> Delete 
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                      <TableFooter>
                          <TableRow>
                          <TablePagination
                              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                              colSpan={12}
                              count= {locations.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              slotProps={{
                                  select: {
                                  inputProps: {
                                      'aria-label': 'rows per page',
                                  },
                                  native: true,
                                  },
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                              />
                          </TableRow>
                      </TableFooter>
                  </Table>
                </Box>
                ) : (
                  <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10'>
                    <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
                  </div>
                )
          )
            
          }

    <CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
        if(isAddModalOpen){
            toggleAddModal();
        } else if(isUpdateModalOpen) {
            toggleUpdateModal();
        }
    }} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
            {
                isAddModalOpen ? 'Add Location' : 'Update Location' 
            }
        </h1>
        <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
            <div>
                <input type='hidden' name='company_id' value={values.company_id || ''}/>
            </div>
            <div className='flex justify-between gap-5'>
                <div className='w-full'>
                    <label>Name:</label>
                    <input type='text' name='name' placeholder='Enter Name' 
                        value={values.name}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.name && touched.name ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p className='text-red-500 pl-2 italic'>{errors.name}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Phone:</label>
                    <input type='number' name='phone_number' placeholder='Enter Phone'
                        value={values.phone_number}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.phone_number && touched.phone_number ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.phone_number && errors.phone_number ? <p className='text-red-500 pl-2 italic'>{errors.phone_number}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Description:</label>
                    <input type='text' name='description' placeholder='Enter Description' 
                        value={values.description}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.description && touched.description ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.description && errors.description ? <p className='text-red-500 pl-2 italic'>{errors.description}</p> : null}
                </div>
            </div>
            {
            isAddModalOpen ? (
                <div className='flex justify-between gap-5 mt-5'>
                    <div className='w-full'>
                        <label>Password:</label>
                        <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password'
                            value={values.password}
                            className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.password && touched.password ? 'border-red-600': '')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <button type='button' className='w-full flex justify-end pr-10 -mt-7 text-slate-500'
                        onClick={()=>setShowPassword(!showPassword)}
                        >
                        {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}/>}
                        </button>
                        {touched.password && errors.password ? <p className='text-red-500 pl-2 mt-3 italic'>{errors.password}</p> : null}
                    </div>
                </div>
            ) : null
        }
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Address:</label>
                    <input type='text' name='address' placeholder='Enter Address'
                        value={values.address}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.address && touched.address ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.address && errors.address ? <p className='text-red-500 pl-2 italic'>{errors.address}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Email:</label>
                    <input type='text' name='email' placeholder='Enter Email'
                        value={values.email}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.email && touched.email ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? <p className='text-red-500 pl-2 italic'>{errors.email}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Country:</label>
                    <select name='country' placeholder='Enter Country'
                        value={values.country}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.country && touched.country ? 'border-red-600': '')}
                        onChange={(e) => {
                            handleChange(e); 
                            getStates(e.target.value);
                            getCities(e.target.value);
                        }}
                        onBlur={handleBlur}
                    >
                        <option>Country</option>
                        {
                           countries?.map((country , index) => (
                                <option key={index} value={country.isoCode} >{country.name}</option>
                           ))
                        }
                    </select>
                    {touched.country && errors.country ? <p className='text-red-500 pl-2 italic'>{errors.country}</p> : null}
                </div>
                <div className='w-full'>
                    <label>State:</label>
                    <select name='state' placeholder='Enter State'
                        value={values.state}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.state && touched.state ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option>State</option>
                        {
                           states?.map((state , index) => (
                                <option key={index} value={state.isoCode} >{state.name}</option>
                           ))
                        }
                    </select>
                    {touched.state && errors.state ? <p className='text-red-500 pl-2 italic'>{errors.state}</p> : null}
                </div>
                <div className='w-full'>
                    <label>City:</label>
                    <select name='city' placeholder='Enter City' 
                        value={values.city}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.city && touched.city ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option>City</option>
                        {
                           cities?.map((city , index) => (
                                <option key={index} value={city.name} >{city.name}</option>
                           ))
                        }
                    </select>
                    {touched.city && errors.city ? <p className='text-red-500 pl-2 italic'>{errors.city}</p> : null}
                </div>
                <div className='w-full'>
                    <label className='font-medium'>Postal Code:</label>
                    <input type='text' name='postal_code' placeholder='Enter Potal Code' 
                        value={values.postal_code}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.postal_code && touched.postal_code ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.postal_code && errors.postal_code ? <p className='text-red-500 pl-2 italic'>{errors.postal_code}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Opening Hour:</label>
                    <input type='text' name='opening_hours' placeholder='Enter Opening Hour'
                        value={values.opening_hours}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.opening_hours && touched.opening_hours ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.opening_hours && errors.opening_hours ? <p className='text-red-500 pl-2 italic'>{errors.opening_hours}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Closing Hour:</label>
                    <input type='text' name='closing_hours' placeholder='Enter Closing Hour'
                        value={values.closing_hours}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.closing_hours && touched.closing_hours ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.closing_hours && errors.closing_hours ? <p className='text-red-500 pl-2 italic'>{errors.closing_hours}</p> : null}
                </div>
            </div>
            <div className="flex justify-end items-center pt-3 mt-3 border-t">
                <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>Submit</button>
            </div>
        </form>
      </CustomModal>
      
      <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Location</h1>
        <div className='m-5'>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Name:</h1>
                <span className='text-xl'>{location.name}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Description:</h1>
                <span className='text-xl'>{location.description}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Address:</h1>
                <span className='text-xl'>{location.address}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Postal Code:</h1>
                <span className='text-xl'>{location.postal_code}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Phone:</h1>
                <span className='text-xl'>{location.phone_number}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Opening Hour:</h1>
                <span className='text-xl'>{location.opening_hours}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Closing Hour:</h1>
                <span className='text-xl'>{location.closing_hours}</span>
            </div>
        </div>
        
      </CustomModal>
      {
        isPopupOpen && (
            <Popup 
             onClose={() => setIsPopupOpen(false)}
             alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
             message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
             />
                
        )
      }
      
      <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
        <div className='m-5'>
            <h2 className="text-xl font-medium">Delete Location?</h2>
            <p className="text-slate-500 mt-3">Are you sure you want to delete this location?</p>
            <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteLocation}>Delete</button>
        </div>
      </CustomModal>
    </>
  )
}

export default Locations