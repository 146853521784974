import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import defaultUser from '../../../images/user_default.jpg'
import RenderFiltersType from './RenderFiltersType';
import RenderFilters from './RenderFilters';
import { filterMembers } from '../reports/member_reports/member';

const RenderTable = ({
    members,
    showRestoreModal,
    showViewModal,
    showUpdateModal,
    showDeleteModal,
    packages,
    handleExport
}) => {

    const [filterType, setFilterType] = useState('');
    const [filterValue, setFilterValue] = useState({});
    const [filteredMembers , setFilteredMembers] = useState(members);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page , setPage ] = useState(0)

    const paginatedMembers = !filteredMembers || filteredMembers.length === 0 ?
    [] :
     rowsPerPage === -1
    ? filteredMembers 
    : filteredMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterChange = (value) => setFilterType(value);
    const handleFilterValueChange = (value) => {
        setFilterValue(value)
        setFilteredMembers(filterMembers(members , value))
    };

    const renderActions = (member) => (
        <div className="flex gap-2">
            {!member.is_active && !member.is_new && (
                <div
                    className="text-slate-700 font-bold hover:cursor-pointer hover:text-blue-700"
                    onClick={() => showRestoreModal(member._id)}
                >
                    <FontAwesomeIcon icon={faRecycle} /> Restore
                </div>
            )}
            <div
                className="text-slate-700 font-bold hover:cursor-pointer hover:text-green-700"
                onClick={() => showViewModal(member._id)}
            >
                <FontAwesomeIcon icon={faEye} /> View
            </div>
            <div
                className="text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]"
                onClick={() => showUpdateModal(member._id)}
            >
                <FontAwesomeIcon icon={faPen} /> Update
            </div>
            <div
                className="text-slate-700 font-bold hover:cursor-pointer hover:text-red-700"
                onClick={() => showDeleteModal(member._id)}
            >
                <FontAwesomeIcon icon={faTrash} /> Delete
            </div>
        </div>
    );

    return (
        <>
            <div className="w-full flex justify-around items-center my-5">
                <RenderFiltersType filterType={filterType} handleFilterChange={handleFilterChange} />
                <button
                    className="bg-blue-700 text-white font-bold text-sm py-1 px-6 rounded-full hover:bg-blue-800"
                    onClick={() => handleExport(filteredMembers , filterValue)}
                >
                    Export
                </button>
            </div>
            <RenderFilters filterType = {filterType} filterValue={filterValue} handleFilterValueChange = {handleFilterValueChange} packages={packages}/> 
            {
               filteredMembers && filteredMembers.length ? (
                    <div className='overflow-auto'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Photo</TableCell>
                                <TableCell>Member ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Club</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedMembers.map((member, index) => (
                                <TableRow hover key={index}>
                                    <TableCell>
                                        <div className="w-20 h-20 rounded-full overflow-hidden border border-gray-300">
                                            <img
                                                src={member.photo || defaultUser}
                                                alt={member.name}
                                                className="w-full h-full object-cover"
                                                onError={(e) => (e.target.src = defaultUser)}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>{member.member_id}</TableCell>
                                    <TableCell>{member.name}</TableCell>
                                    <TableCell>{member.email}</TableCell>
                                    <TableCell>{member.address}</TableCell>
                                    <TableCell>{member.phone}</TableCell>
                                    <TableCell>{member.location.name}</TableCell>
                                    <TableCell>
                                        <span
                                            className={`p-2 rounded-full text-white ${
                                                member.is_active ? 'bg-green-700' : 'bg-red-700'
                                            }`}
                                        >
                                            {member.is_active ? 'active' : 'inactive'}
                                        </span>
                                    </TableCell>
                                    <TableCell>{renderActions(member)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={9}
                                    count={filteredMembers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    </div>
                ) : (
                    <div className="bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10 animate-fadeIn">
                        <h3 className="text-xl font-bold text-center text-slate-500">No Records Found</h3>
                    </div>
                )
            }
        </>
    )
};

export default RenderTable;
