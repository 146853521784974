import React from 'react'
import './landingPage.css'
import logo from '../../images/2.jpg'
import acccess from '../../images/feature1.jpg'
import f2 from '../../images/feature2.jpg'
import f3 from '../../images/feature3.jpg'
import f4 from '../../images/management.jpg'
import f5 from '../../images/online-booking.jpg'
import f6 from '../../images/online-sign-up.jpg'
import f7 from '../../images/mobile.jpg'
import f8 from '../../images/automation.jpg'
import f9 from '../../images/3.jpg'
import f10 from '../../images/truely_mobile.jpg'
import { useNavigate } from 'react-router-dom';



function Home() {

  const navigate = useNavigate();
  return (
    <>
      {/* hero */}
      <div className='home-image -mt-[100px] '>
        <div className='headerBox'>
          <h2>Ultimate Gym Management Solution</h2>
          <p> <span className='font-bold'> GymXS</span> is for gym owners like you. You want to streamline your business with powerful membership software that minimizes hassle, increases member retention, and helps both you and your members to achieve your goals</p>
          <div className='text-center mt-10'><button  class="bg-[#F7931E] text-white font-medium p-3 rounded-lg "   onClick={() => navigate(`/signup`)}> SIGN UP NOW</button> </div>
        </div>
      </div>

      {/* part2 */}
      <div className="flex bg-lightGray py-10" >
        <div className="w-full h-full pl-[60px] block max-w-full">
          <img src={logo} />
        </div>
        <div className=' w-full px-10'>
          <div className='pl-5  text-[30px]'>
            <h3 className='text-[#DA4425]'>
              GymXS is convenient gym and health club management solution</h3>
          </div>
          <div className='pl-5 text-[16px]'>
            <p>GymXS is designed to make it easy to maintain detailed records of your members and their memberships, manage gym assets and staff, process and track sales. Designed to fit clubs of all sizes, this gym management solution is feature-rich. With a full attendance management system, point of sale, website integration, billing integration, a mobile app for staff and members, and integrated access control, GymXS has all you need to more efficiently run your gym</p>
          </div>
        </div>
      </div>

      {/* part3 */}

      <div className="container2 px-10 grid grid-cols-2 gap-4">
        <div className="flex flex-col justify-center space-y-4 p-10 py-20">
          <h2 className='text-[#DA4425] text-[30.8px]'>
            24/7 Gym Access Control
          </h2>
          <h3 className='text-[#5787C1] text-[21px]'>
            Whether you want a quick 24/7 solution, or complete control over member movement within your facility, GymXS has the answer
          </h3>
          <p className='text-[16px]'>
            GymXS’s Access Control System provides an easy to implement and convenient way for members to access your facility giving you added security and peace of mind. Our network-based reader is designed as a low-cost and reliable way to provide access control for your members.
          </p>
          <div className='justify-center'>
            <button className='  border-[#f7931e] bg-[#f7931e] text-white  px-4 py-2'  onClick={() => navigate(`/signup`)}>
              SIGN UP NOW
            </button>
          </div>
        </div>
      </div>

      {/* part4 */}

      <div className='bg-gray-200 p-10'>
        <div className='w-[80%] mx-auto  '>

          <div >
            <h2 className="text-[#d73925] text-[30.8px]">Why GymXS is the best solution</h2>
          </div>
          <div className="flex gap-10 text-[22.4px]">
            <div >

              <ul className='space-y-4'>
                <li >
                  <i className="fa fa-lg fa-check text-success text-green-900"></i> Manage your Operation more Efficiently
                </li>
                <li>
                  <i className="fa fa-lg fa-check text-success text-green-900"></i> Easier Customer Acquisition
                </li>
                <li>
                  <i className="fa fa-lg fa-check text-success text-green-900"></i> Reduce Management Administration
                </li>
              </ul>
            </div>
            <div>
              <ul className='space-y-4'>
                <li>
                  <i className="fa fa-lg fa-check text-success text-green-900"></i> Improve Cash Flow
                </li>
                <li>
                  <i className="fa fa-lg fa-check text-success text-green-900"></i> Increase Retention
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      {/* part5 */}
      <div className="bg-gray-200">
  <div>
    <h2 className="text-[#d73925] text-center text-[21px]">Features You'll Love</h2>
  </div>
  <div className="grid grid-cols-4 gap-4 p-10">
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={acccess} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">24/7 Access Control</h2>
      <p className="text-sm">
        Open your gym for longer with confidence, saving time and money with GymXS’s advanced access control system.
      </p>
    </div>
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={f2} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">Integrated Billing</h2>
      <p className="text-sm">
        Get paid on time without the administrative stress with GymXS’s automated payment processing.
      </p>
    </div>
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={f3} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">Point of Sale</h2>
      <p className="text-sm">
        Boost your bottom line with smart POS & tracking system that works on any device.
      </p>
    </div>
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={f4} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">Member Management</h2>
      <p className="text-sm">
        Deliver an exceptional member experience from the beginning. Go paperless with online signups, website integration and a member dashboard that you’ll love.
      </p>
    </div>
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={f5} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">Online Booking</h2>
      <p className="text-sm">
        Give your members the flexibility to make online bookings for classes and PT sessions - wherever, whenever.
      </p>
    </div>
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={f6} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">Online Sign Up</h2>
      <p className="text-sm">
        Automate your sign up process and increase your membership numbers with seamless website integration. You'll love the live-updating timetable, too.
      </p>
    </div>
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={f7} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">Go Mobile</h2>
      <p className="text-sm">
        Free yourself from the front desk and experience the benefits of having your entire gym system at your fingertips.
      </p>
    </div>
    <div className="flex flex-col items-center text-center p-4 bg-gray-200">
      <img src={f8} className="w-[60px] h-[60px] mb-2" />
      <h2 className="text-[#d73925] text-[21px]">Automation</h2>
      <p className="text-sm">
        Marketing and member retention tools that you can simply set and forget.
      </p>
    </div>
  </div>
</div>


      {/* part5 */}

      <div className='flex  p-10'>
        <div className='pl-10 pr-10'>
          <img src={f9}></img>
        </div>
        <div className='pt-[40px] space-y-4'>
          <h2 className='text-[#d73925] text-[30.8px]'>
            Touch Point Of Sale
          </h2>
          <h3 className='text-[#467abd;] text-[21px]'>
            Making transactions quick and easy for staff and customers with the user friendly touch screen interface
          </h3>
          <p className='text-base  text-[16.8px]'>
            Set up and manage products with ease. Making sales and creating chargeable accounts for members with varying quantities and discounts, is now easier than ever. Find out
          </p>
          <div className='flex justify-center'>
            <button className='  border-[#f7931e] bg-[#f7931e] text-white  px-4 py-2'  onClick={() => navigate(`/signup`)}>
              SIGN UP NOW
            </button>
          </div>
        </div>
      </div>


      {/* part6 */}

      <div className='flex p-20 pt-20  bg-gray-200'>
        <div className='pl-32 w-[55%]'>
          <h2 className='text-[#d73925] text-[30.8px]'>
            Freedom from your Desk
          </h2>
          <h3 className='text-[#467abd] text-[21px]'>
            The mobile version GymXS allows you to work from anywhere, any time
          </h3>
          <p className='text-base text-[16px] '>
            The mobile version GymXS allows you to work from anywhere, any time </p>

        </div>

        <div className='pl-15 pr-15'>
          <img src={f10}></img>
        </div>

      </div>



      {/* part7 */}




      <div
        className=" h-[70vh]  flex items-center justify-center cont"

      >

        <div className="bg-white bg-opacity-70 p-10 rounded shadow-lg  max-w-xl">
          <h2 className="text-[30.8px] font-bold text-[#DA4425] mb-4">Integrated Billing</h2>
          <p className="text-[16.8px] text-[#5787C1] mb-2">
            Let GymXS help you manage member's accounts with invoicing, billing integration, and credit card payments.
          </p>
          <p className="text-[16.8px]">
            Whether you want to automate your billing and reduce admin costs, or you are looking for a more hands-on solution, GymXS gives you flexibility and control over your payments process.
          </p>
        </div>
      </div>



    </>

  )
}

export default Home