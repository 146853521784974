import React, { useEffect, useState } from 'react'
import SideMenu from './SideMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDashboard } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import './layout.css'

function SideMenuTabs() {
  const [selectedMenu , setSelectedMenu] = useState(0)
  const [userName ,setUserName] = useState('')
  const [userEmail , setUserEmail] = useState('')
  const [role , setRole] = useState('')
  useEffect(() => {
    const tabIndex = sessionStorage.getItem('tab-index');
    if (tabIndex !== null) {
      setSelectedMenu(JSON.parse(tabIndex));
    }

    const sessionStr = sessionStorage.getItem('user-info');

    if(sessionStr){
      const session = JSON.parse(sessionStr);
      setRole(session.user.role)
      setUserName(session.user.name)
      setUserEmail(session.user.email)
    }
  } , [])

  const handleTabChange = (index) => {
    setSelectedMenu(index)
    
    sessionStorage.setItem('tab-index', JSON.stringify(index));
  }
  return (
   <>
      {
        SideMenu(role).map((menu , index) => (
          <div key={index}>
          {
            menu.ignore === true ? (
              null
            ) : (
                <Link key={index} to={menu.url} className=''>
                  <div
                    className={'font-medium cursor-pointer border-b border-slate-700 p-3 flex items-center gap-3 ' + (selectedMenu === index ? 'bg-white text-[#03394f] rounded-s-full':'hover:bg-[#03394f] text-white ')}
                    onClick={() => handleTabChange(index)}
                    >
                    <span>{menu.icon}</span>
                    <h1>{menu.title}</h1>
                  </div>
                </Link>
              )
          }
          </div>
        ))
      }
   </>
   
  )
}

export default SideMenuTabs