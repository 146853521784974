import React from 'react'

function RenderFilters({filterType , filterValue , handleFilterValueChange , packages}) {
  return (
    <>
        {filterType === "date" && (
            <div className='w-full text-center my-5 text-base'>
                <input type='date' 
                value={filterValue.date}
                onChange={(e) => {
                    handleFilterValueChange({date: e.target.value});
                }}
                name='date'
                placeholder='Date' 
                className={'w-1/2 border border-slate-300 bg-slate-100 p-1 px-2 rounded-full outline-0'}
                />
            </div>
        )}

        {filterType === "month" && (
            <div className='w-full text-center my-5 text-base'>
                <select name="month" 
                value={filterValue.month} 
                placeholder='Select Month' 
                onChange={(e) => {
                    handleFilterValueChange({month: e.target.value});
                }}
                className={'w-1/2 border border-slate-300 bg-slate-100 p-1 px-3 rounded-full outline-0'}
                >
                    <option value="">Select Month</option>
                    <option value="Jan">January</option>
                    <option value="Feb">February</option>
                    <option value="Mar">March</option>
                    <option value="Apr">April</option>
                    <option value="May">May</option>
                    <option value="Jun">June</option>
                    <option value="Jul">July</option>
                    <option value="Aug">August</option>
                    <option value="Sep">September</option>
                    <option value="Oct">October</option>
                    <option value="Nov">November</option>
                    <option value="Dec">December</option>
                </select>
            </div>
        )}           

            {filterType === "gender" && (
                <div className='w-full text-center my-5 text-base'>
                    <select name="gender" 
                    value={filterValue.gender} 
                    placeholder='Select Gender' 
                    onChange={(e) => {
                        handleFilterValueChange({gender: e.target.value});
                    }}
                    className={'w-1/2 border border-slate-300 bg-slate-100 p-1 px-3 rounded-full outline-0'}
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
            )}
            {filterType === "package_id" && (
                <div className='w-full text-center my-5 text-base'>
                    <select name="package_id" 
                    value={filterValue.package_id} 
                    placeholder='Select Package' 
                    onChange={(e) => {
                        handleFilterValueChange({package_id: e.target.value});
                    }}
                    className={'w-1/2 border border-slate-300 bg-slate-100 p-1 px-3 rounded-full outline-0'}
                    >
                        <option value="">Select Package</option>
                        {
                            packages?.map((member_package , index) => (
                                <option key={index} value={member_package?._id}>{member_package?.title}</option>
                            ))
                        }
                    </select>
                </div>
            )}
    </>
  )
}

export default RenderFilters