import { Box, CircularProgress, createTheme, ThemeProvider } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Popup from '../../../base-components/Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import CustomModal from '../../../base-components/CustomModal';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { memberSchema } from '../../../schemas';
import { Report } from '../reports/member_reports/member';
import RenderTable from './RenderTable.js';
import './members.css'

const BASE_URL = process.env.REACT_APP_BASE_URL;

const theme = createTheme({
    palette: {
      primary: {
        main: '#ffffff'
      },
    },
  });

  const mainTheme = createTheme({
    palette: {
      primary: {
        main: '#033b51'
      },
    },
  });

function Members() {

  const navigate = useNavigate()
  const selectPhotoRef = useRef(null)
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

    const [cameraStream, setCameraStream] = useState(null);
    const [members , setMembers] = useState([])
    const [active_members , setActiveMembers] = useState([])
    const [inactive_members , setInactiveMembers] = useState([])
    const [restored_members , setRestoredMembers] = useState([])
    const [new_members , setNewMembers] = useState([])
    const [member , setMember] = useState({})
    const [role , setRole] = useState('')
    const [locations , setLocations] = useState([])
    const [trainers , setTrainers] = useState([])
    const [showPassword , setShowPassword] = useState(false)
    const [memberToDelete , setMemberToDelete] = useState('')
    const [memberToRestore , setMemberToRestore] = useState('')
    const [memberToUpdate , setMemberToUpdate] = useState('')
    const [isAddModalOpen , setIsAddModalOpen] = useState(false)
    const [isViewModalOpen , setIsViewModalOpen] = useState(false)
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [isRestoreModalOpen , setIsRestoreModalOpen] = useState(false)
    const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
    const [countries , setCountries] = useState([])
    const [states , setStates] = useState([])
    const [cities , setCities] = useState([])
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [tabType , setTabType] = useState('all')
    const [tabData , setTabData] = useState([])
    const [packages , setPackages] = useState([])
    const [member_package_amount , setMemberPackageAmount] = useState('')
    const [member_registration_amount , setMemberRegistrationAmount] = useState('')
    const [company_id , setCompanyID] = useState('')
    const [location_id , setLocationID] = useState('')
    const [photo , setPhoto] = useState('')
    const [loading , setLoading] = useState(false)

    useEffect(()=> {
        const sessionStr = sessionStorage.getItem('user-info');
        if(sessionStr){
          const session = JSON.parse(sessionStr);
          setRole(session.user.role)
          setCompanyID(session.user.company_id)
          setLocationID(session.user.location_id)
        }
         else {
          navigate('/login');
        }
  },[])
  useEffect(() => {
    if (role) {
      setLoading(true);
      const fetchData = async () => {
        try {
          await Promise.all([
            getMembers(),  // Returns a Promise
            getCountries(),
            getLocations(),
            getPackages(),
            getTrainers(),
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false); // Ensures loading is set to false after all calls
        }
      };
      fetchData();
    }
  }, [role]);
  
useEffect(() => {
    if (videoRef.current && cameraStream) {
      // This ensures that the video element is available before assigning the stream
      videoRef.current.srcObject = cameraStream;
  
      // Wait for the video to load and then play
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
      };
    }
  }, [cameraStream]); // Re-run when cameraStream changes
  

  async function getPackages() {
    let endpoint = '';
    if (role === 'SuperAdmin') {
        endpoint = `package/getPackages`;
    } else if (role === 'Admin') {
        endpoint = `package/getPackagesByCompanyID/${company_id}`;
    } else if(role === 'ClubAdmin') {
        endpoint = `package/getPackagesByLocationID/${location_id}`;
    }
    return fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setPackages(data.packages)
        })
        .catch((error) => console.log(error));
  }

  async function getTrainers() {
    let endpoint = '';
    if (role === 'SuperAdmin') {
        endpoint = `trainers/get-trainers`;
    } else if (role === 'Admin') {
        endpoint = `trainers/get-trainers-by-company/${company_id}`;
    } else if(role === 'ClubAdmin') {
        endpoint = `trainers/get-trainers-by-location/${location_id}`;
    }
    return fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setTrainers(data.trainers)
        })
        .catch((error) => console.log(error));
  }

  async function getLocations() {
    let endpoint = '';
    if (role === 'SuperAdmin') {
        endpoint = 'locations/getLocations';
    } else if (role === 'Admin') {
        endpoint = `locations/getLocationsByCompanyID/${company_id}`;
    }
    return fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setLocations(data.locations)
        })
        .catch((error) => console.log(error));
  }

  async function getCountries () {
      return fetch(BASE_URL + `country/getCountries`)
      .then((response) => response.json())
      .then((data) => {
          setCountries(data)
          
      })
      .catch((error) => console.log(error));
  
  }

  const getStates = async (value) => {
      await fetch(BASE_URL + `country/getStatesById/${value}`)
      .then((response) => response.json())
      .then((data) => {
          setStates(data)
          
      })
      .catch((error) => console.log(error));
  }

  const getCities = async (value) => {
      await fetch(BASE_URL + `country/getCitiesById/${value}`)
      .then((response) => response.json())
      .then((data) => {
          setCities(data)
      })
      .catch((error) => console.log(error));
  }

  async function getMemberPackage (id) {
    await fetch(BASE_URL + `package/getPackageById/${id}`)
    .then((response) => response.json())
    .then((data) => {
        setMemberPackageAmount(data.packages.membership_fee)
        setMemberRegistrationAmount(data.packages.registration_fee)
        
    })
    .catch((error) => console.log(error));
}
  
const bufferToBase64 = (buffer) => {
    const uint8Array = new Uint8Array(buffer);
    let binary = '';
    uint8Array.forEach(byte => {
      binary += String.fromCharCode(byte);
    });
    return `data:image/jpeg;base64,${window.btoa(binary)}`;
  };

  async function getMembers() {
    const endpoint =
      role === "SuperAdmin"
        ? "members/get-all-members"
        : role === "Admin"
        ? `members/getMembersByCompanyID/${company_id}`
        : `members/getMembersByLocationID/${location_id}`;
  
    return fetch(BASE_URL + endpoint)
      .then((response) => response.json())
      .then((data) => {
        const membersWithBase64 = data.members.map((member) => {
          if (member.photo?.type === "Buffer") {
            member.photo = bufferToBase64(member.photo.data);
          }
          return member;
        });
  
        setMembers(membersWithBase64);
        const activeMembersWithBase64 = membersWithBase64.filter(member => member.is_active === true);
        setActiveMembers(activeMembersWithBase64);
        const inactiveMembersWithBase64 = membersWithBase64.filter(member => member.is_active === false);
        setInactiveMembers(inactiveMembersWithBase64);
        const restoredMembersWithBase64 = membersWithBase64.filter(member => member.is_restored === true);
        setRestoredMembers(restoredMembersWithBase64);
        const newMembersWithBase64 = membersWithBase64.filter(member => member.is_new === true);
        setNewMembers(newMembersWithBase64);
        setTabData([
            { key: 'inactive', label: 'Inactive Members', members: inactiveMembersWithBase64},
            { key: 'active', label: 'Active Members', members: activeMembersWithBase64},
            { key: 'all', label: 'All Members', members: membersWithBase64},
            { key: 'new', label: 'New Members', members: newMembersWithBase64},
            { key: 'restored', label: 'Restored Members', members: restoredMembersWithBase64},
        ])
        console.log("got in members");
      })
      .catch((error) => {
        setMembers([])
        setActiveMembers([])
        setInactiveMembers([])
        setRestoredMembers([])
        setNewMembers([])
        setTabData([
            { key: "inactive", label: "Inactive Members", members: [] },
            { key: "active", label: "Active Members", members: [] },
            { key: "all", label: "All Members", members: [] },
            { key: "new", label: "New Members", members: [] },
            { key: "restored", label: "Restored Members", members: [] },
          ]);
        console.log("got in members error");
      });
  }


  async function getMemberByID (id) {
      await fetch(BASE_URL + `members/get-member/${id}`)
      .then((response) => response.json())
      .then((data) => {
          const photo64 = bufferToBase64(data.member.photo?.data)
          data.member.photo = base64ToBlob(photo64);
          setMember(data.member)
          setValues(data.member);
          setPhoto(photo64)
      })
      .catch((error) => console.log(error));
  }
  
  const handleAddMember = async (values, actions) => {
        values.company_id = company_id   
        
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === 'photo' && values[key]) {
              formData.append(key, values[key], 'photo.jpg'); 
            } else {
              formData.append(key, values[key]); 
            }
          });

        setLoading(true)
          const requestOptions = {
              method: "POST",
            //   headers: {
            //       "Content-Type": "application/json",
            //   },
              body: formData,
          };
  
          try {
              const response = await fetch(BASE_URL + `members/create-member`, requestOptions);
  
              if (!response.ok) {
                  const data = await response.json()
                  console.error('Response not ok:' , data);
                  setIsFailureAlert(true)
                  setFailureMessage(data.message)
                  setIsPopupOpen(true)
                  setTimeout(() => {
                      setIsPopupOpen(false)
                  } , 5000)
              } else {
                  setIsAddModalOpen(false)
                  actions.resetForm()
                  setPhoto('')
                  await getMembers()
                  setIsSuccessAlert(true)
                  setSuccessMessage('Member added Successfully')
                  setIsPopupOpen(true)
                  setTimeout(() => {
                      setIsPopupOpen(false)
                  } , 5000)
              }
          } catch (error) {
              console.error('Caught error:', error);
              setIsFailureAlert(true) 
              setFailureMessage('Failed to add Member')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              } , 5000)
          } finally {
              setLoading(false);
          }
  
  }

  const handleUpdateMember = async (values, actions) => {

        const formData = new FormData();

        Object.keys(values).forEach((key) => {
            if (key === 'photo' && values[key]) {
              formData.append(key, values[key], 'photo.jpg'); 
            } else {
              formData.append(key, values[key]); 
            }
          });
        setLoading(true)
      const requestOptions = {
          method: "POST",
        //   headers: {
        //       "Content-Type": "application/json",
        //   },
          body: formData,
      };

      try {
          const response = await fetch(BASE_URL + `members/update-member/${memberToUpdate}`, requestOptions);

          if (!response.ok) {
              console.error('Response not ok:', response);
              setIsFailureAlert(true)
              setFailureMessage('Failed to Update Member')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          } else {
              actions.resetForm()
              setIsUpdateModalOpen(false)
              await getMembers()
              setIsSuccessAlert(true)
              setSuccessMessage('Member Updated Successfully')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          }
      } catch (error) {
          console.error('Caught error:', error);
          setIsFailureAlert(true)
          setFailureMessage('Failed to Update Member')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      } finally {
          setLoading(false);
      }

}

async function restoreMember () {
    await fetch(BASE_URL + `members/restore-member/${memberToRestore}`)
    .then((response) => response.json())
    .then((data) => {
        toggleRestoreModal()
        getMembers()
        setIsSuccessAlert(true)
        setSuccessMessage('Member Restored Successfully')
        setIsPopupOpen(true)
        setTimeout(() => {
            setIsPopupOpen(false)
        }, 5000)
    })
    .catch((error) => {
        toggleRestoreModal()
        getMembers()
        setIsFailureAlert(true)
        setFailureMessage('Member not Restored Successfully' + error)
        setIsPopupOpen(true)
        setTimeout(() => {
            setIsPopupOpen(false)
        }, 5000)
    });
}

  async function deleteMember () {
      const requestOptions = {
          method: "DELETE",
          headers: {
              "Content-Type": "application/json",
          },
      };
      await fetch(BASE_URL + `members/delete-member/${memberToDelete}` , requestOptions)
      .then( async (response) => {
        const responseData = await response.json()
        if(responseData) {
            await getMembers()
            setIsDeleteModalOpen(false)
            setIsSuccessAlert(true)
            setSuccessMessage('Member deleted Successfully')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        }
    })
      .catch((error) => {
          console.log(error)
          setIsFailureAlert(true)
          setFailureMessage('Failed to delete Member')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      });
  }

    function toggleAddModal() {
      setIsAddModalOpen(!isAddModalOpen)
      setPhoto('')
      handleCloseCamera()
      resetForm()
  }
  function toggleViewModal() {
      setIsViewModalOpen(!isViewModalOpen)
      resetForm()
  }

  function toggleUpdateModal() {
      setIsUpdateModalOpen(!isUpdateModalOpen)
      setPhoto('')
      setFieldValue('photo' , '')
      handleCloseCamera()
      resetForm()
  }
  function toggleDeleteModal() {
      setIsDeleteModalOpen(!isDeleteModalOpen)
  }


  function toggleRestoreModal() {
    setIsRestoreModalOpen(!isRestoreModalOpen)
}

  function showAddModal(id) {
      setIsAddModalOpen(true);
  }
  function showViewModal(id) {
      getMemberByID(id);
      setIsViewModalOpen(true);
  }
  function showUpdateModal(id) {
      getMemberByID(id);
      setMemberToUpdate(id);
      setIsUpdateModalOpen(true);
  }
  function showDeleteModal(id) {
      setMemberToDelete(id);
      setIsDeleteModalOpen(true);
  }

  function showRestoreModal(id) {
    setMemberToRestore(id);
    setIsRestoreModalOpen(true);
}

const handleExport = (filteredMembers , filter) => {
    Report(filteredMembers , filter)
}

  const base64ToBlob = (base64String) => {
    const arr = base64String?.split(",");
    // if(!arr || arr.length === 0) return;
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract MIME type
    const bstr = atob(arr[1]); // Decode Base64
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n); // Convert Base64 to binary data
    }
  
    return new Blob([u8arr], { type: mime });
  };

  const handleSelectPhotoClick = (e) => {
    e.preventDefault();
    if (selectPhotoRef.current) {
      selectPhotoRef.current.click();
    } else {
      console.error('selectPhotoRef is not properly initialized.');
    }
  };

  const handleTakePhotoClick = async (e) => {
    e.preventDefault();

    if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
        setCameraStream(null);
      }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
  
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
  
        videoRef.current.oncanplay = () => {
          videoRef.current.play();
        };
      } else {
        console.error('Video element is not properly initialized');
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const handleCloseCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
    }
  };
  

  const handleCapturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
        
      const context = canvasRef.current.getContext("2d");
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(
        videoRef.current,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight
      );

      // Get the photo data as a URL
      const photoData = canvasRef.current.toDataURL("image/png");
      const photoBlob = base64ToBlob(photoData);
      setFieldValue("photo", photoBlob);
      setPhoto(photoData)

      // Stop the camera stream
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
        setCameraStream(null);
      }
    }
  };

    const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm , setFieldValue} = useFormik({
      initialValues: {
        id:'',
        name: '',
        phone: '',
        photo: null,
        email:'',
        password: '',
        address: '',
        is_trainer: false,
        trainer_id: '',
        country: '',
        state:'',
        city: '',
        postal_code: '',
        cnic:'',
        age: '',
        gender: 'male',
        height:'',
        height_units: 'ft',
        weight: '',
        weight_units: 'kg',
        emergency_contact: '',
        emergency_contact_no: '',
        disease: '',
        company_id: '',
        location_id: role === 'ClubAdmin' ? location_id : '' ,
        package_id:'',
        role: 'User',
      },
      enableReinitialize: true,
      validationSchema: memberSchema(isUpdateModalOpen),
      onSubmit: (values, actions) => {
          if(isAddModalOpen){
              handleAddMember(values, actions); // Call the external function
          } else if(isUpdateModalOpen) {
              handleUpdateMember(values, actions);
          }
      },
  })

  const handleRipple = (e) => {
    const button = e.currentTarget;

    const ripple = document.createElement('span');
    
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    
    ripple.style.width = ripple.style.height = `${diameter}px`;
    ripple.style.left = `${e.clientX - button.offsetLeft - radius}px`;
    ripple.style.top = `${e.clientY - button.offsetTop - radius}px`;
    
    ripple.classList.add('ripple');
    
    const existingRipple = button.querySelector('.ripple');
    if (existingRipple) {
      existingRipple.remove();
    }

    button.appendChild(ripple);
  };

//   const addTabData = () => {
//     const tabData = [
//         { key: 'inactive', label: 'Inactive Members', members: inactive_members},
//         { key: 'active', label: 'Active Members', members: active_members},
//         { key: 'all', label: 'All Members', members: members},
//         { key: 'new', label: 'New Members', members: new_members},
//         { key: 'restored', label: 'Restored Members', members: restored_members},
//       ];
//   }

  return (
    <>
    <div className='flex items-center justify-between m-5'>
        <div className='font-bold text-2xl'>Members</div>
        <div className='flex justify-center items-center gap-5'>
            <button className='ripple-button bg-[#033b51] text-white font-bold p-2 rounded-lg relative overflow-hidden hover:bg-[#022A3A]'
            onClick={showAddModal} 
             onMouseDown={handleRipple}
            >Add Member</button>
        </div>
    </div>
    {
        loading ? <ThemeProvider theme={mainTheme}><div className='flex justify-center items-center mt-20'><CircularProgress/></div></ThemeProvider> 
        : (
            <>
            <div className='m-5 flex justify-center items-center'>
                {tabData.map((tab , index) => (
                <button
                    key={tab.key}
                    className={`border border-slate-300 text-slate-600 font-bold p-2 transition-colors duration-300 
                        ${tabType === tab.key ? 'bg-slate-200' : ''} 
                        ${index === 0 ? 'rounded-s-full' : index === tabData.length - 1 ? 'rounded-e-full' : ''}`}
                    onClick={() => setTabType(tab.key)}
                >
                    {tab.label}
                </button>
                ))}
            </div>
        {
            tabData.map((tab , index) => (
                tabType === tab.key && 
                <Box className="border rounded-lg m-5 animate-fadeIn">
                    <RenderTable
                        key={index}
                        members={tab.members}
                        showRestoreModal={showRestoreModal}
                        showViewModal={showViewModal}
                        showUpdateModal={showUpdateModal}
                        showDeleteModal={showDeleteModal}
                        packages = {packages}
                        handleExport = {handleExport}
                        loading={loading}
                    />
                </Box>
            ))
        }
    </>
    )
    }

<CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
    if(isAddModalOpen){
        toggleAddModal();
    } else if(isUpdateModalOpen) {
        toggleUpdateModal();
    }
}} size='2xl'>
    <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
        {
            isAddModalOpen ? 'Add Member' : 'Update Member' 
        }
    </h1>
    <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
        <h1 className='font-bold text-lg text-slate-500 pb-2'>Member Info:</h1>
        <div className="flex justify-between gap-5 mt-4">
            <div className='w-full'>
                <label>Profile Photo:</label>
                <div className='w-full flex gap-2 mx-5 mt-3'>
                    <button className='border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0'
                     onClick={handleSelectPhotoClick}>
                        Select Photo
                    </button>
                    {!cameraStream && (
                    <button
                        className="border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0"
                        onClick={handleTakePhotoClick}
                    >
                        Take Photo
                    </button>
                    )}
                     {cameraStream && (
                        <button
                        type="button"
                        className="border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-red-100 hover:bg-red-200 px-2 py-1 outline-0"
                        onClick={handleCloseCamera}
                        >
                        Close Camera
                        </button>
                    )}
                    {/* <button className='border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0' onClick={handleTakePhotoClick}>Take Photo</button> */}
                </div>
                <input
                    type="file"
                    name="photo"
                    className="hidden"
                    // value={base64ToBlob(member.photo)}
                    ref={selectPhotoRef}
                    onChange={(e) => {
                        const file = e.target.files[0];
                       if (file) {
                            const reader = new FileReader();
                            reader.onload = () => {
                                const base64String = reader.result;
                                if (base64String) {
                                const photoBlob = base64ToBlob(base64String);
                                setFieldValue("photo", photoBlob);
                                setPhoto(base64String)
                                } else {
                                console.error("FileReader failed to read the file.");
                                }
                            };
                            reader.onerror = () => {
                                console.error("Error reading file: ", reader.error);
                            };
                            reader.readAsDataURL(file);
                            e.target.value = "";
                            } else {
                                console.warn("No file selected");
                            }
                            
                    }}
                    onBlur={handleBlur}
                    />
                

                {cameraStream && (
                    <div className="flex flex-col items-center">
                        <video ref={videoRef} className="border mb-2" />
                        <button
                        type="button"
                        className="border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0"
                        onClick={handleCapturePhoto}
                        >
                        Capture Photo
                        </button>
                    </div>
                    )}

                  

                    {photo && (
                    <div className="mt-4">
                        <label>Captured Photo:</label>
                        <img
                        src={photo}
                        alt="Captured"
                        className="border mt-2"
                        />
                    </div>
                    )}

                    {/* Hidden canvas for photo capture */}
                    <canvas ref={canvasRef} className="hidden" />
                {touched.photo && errors.photo ? <p className='text-red-500 pl-2 italic'>{errors.photo}</p> : null}
            </div>
        </div>

        <div className='flex justify-between gap-5 mt-4'>
            <div className='w-full'>
                <label>Name:</label>
                <input type='text' name='name' placeholder='Enter Name' 
                    value={values.name}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.name && touched.name ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.name && errors.name ? <p className='text-red-500 pl-2 italic'>{errors.name}</p> : null}
            </div>
            <div className='w-full'>
                <label>Phone:</label>
                <input type='number' name='phone' placeholder='Enter Phone'
                    value={values.phone}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.phone && touched.phone ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.phone && errors.phone ? <p className='text-red-500 pl-2 italic'>{errors.phone}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Age:</label>
                <input type='number' name='age' placeholder='Enter Age' 
                    value={values.age}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.age && touched.age ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.age && errors.age ? <p className='text-red-500 pl-2 italic'>{errors.age}</p> : null}
            </div>
            <div className='w-full'>
                <label>CNIC:</label>
                <input type='number' name='cnic' placeholder='Enter CNIC'
                    value={values.cnic}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.cnic && touched.cnic ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.cnic && errors.cnic ? <p className='text-red-500 pl-2 italic'>{errors.cnic}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Emergancy Contact Name:</label>
                <input type='text' name='emergency_contact' placeholder='Enter Emergancy Contact Name' 
                    value={values.emergency_contact}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.emergency_contact && touched.emergency_contact ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.emergency_contact && errors.emergency_contact ? <p className='text-red-500 pl-2 italic'>{errors.emergency_contact}</p> : null}
            </div>
            <div className='w-full'>
                <label>Emergancy Contact No:</label>
                <input type='number' name='emergency_contact_no' placeholder='Enter Emergancy Contact No' 
                    value={values.emergency_contact_no}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.emergency_contact_no && touched.emergency_contact_no ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.emergency_contact_no && errors.emergency_contact_no ? <p className='text-red-500 pl-2 italic'>{errors.emergency_contact_no}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Gender:</label>
                <select name='gender' placeholder='Select Gender'
                    value={values.gender}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.gender && touched.gender ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value='male' >Male</option>
                    <option value='female' >Female</option>
                    <option value='other' >Other</option>
                 
                </select>
                {touched.gender && errors.gender ? <p className='text-red-500 pl-2 italic'>{errors.gender}</p> : null}
            </div>
            <div className='w-full'>
                <label>Disease/Medical Condition:</label>
                <input type='text' name='disease' placeholder='Enter Disease/Medical Condition' 
                    value={values.disease}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.disease && touched.disease ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.disease && errors.disease ? <p className='text-red-500 pl-2 italic'>{errors.disease}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Height:</label>
                <input type='number' name='height' placeholder='Enter Height' 
                    value={values.height}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.height && touched.height ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.height && errors.height ? <p className='text-red-500 pl-2 italic'>{errors.height}</p> : null}
            </div>
            <div className='w-full'>
                <label>Height Units:</label>
                <select name='height_units' placeholder='Select Height Units'
                    value={values.height_units}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.height_units && touched.height_units ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value='ft' >Feet (ft)</option>
                    <option value='cm' >Centimeter (cm)</option>
                 
                </select>
                {touched.height_units && errors.height_units ? <p className='text-red-500 pl-2 italic'>{errors.height_units}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Weight:</label>
                <input type='number' name='weight' placeholder='Enter Weight' 
                    value={values.weight}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.weight && touched.weight ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.weight && errors.weight ? <p className='text-red-500 pl-2 italic'>{errors.weight}</p> : null}
            </div>
            <div className='w-full'>
                <label>Weight Units:</label>
                <select name='weight_units' placeholder='Select Weight Unit'
                    value={values.weight_units}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.weight_units && touched.weight_units ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value='kg' >Kilogram (kg)</option>
                    <option value='lb' >Pound (lb)</option>
                 
                </select>
                {touched.weight_units && errors.weight_units ? <p className='text-red-500 pl-2 italic'>{errors.weight_units}</p> : null}
            </div>
        </div>
        <h1 className='font-bold text-lg text-slate-500 py-2 border-t mt-5'>Member Address & Location Details:</h1>
        <div className='flex justify-between gap-5 mt-3'>
            <div className='w-full'>
                <label>Address:</label>
                <input type='text' name='address' placeholder='Enter Address'
                    value={values.address}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.address && touched.address ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.address && errors.address ? <p className='text-red-500 pl-2 italic'>{errors.address}</p> : null}
            </div>
           
            <div className='w-full'>
                <label className=''>Postal Code:</label>
                <input type='text' name='postal_code' placeholder='Enter Potal Code' 
                    value={values.postal_code}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.postal_code && touched.postal_code ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.postal_code && errors.postal_code ? <p className='text-red-500 pl-2 italic'>{errors.postal_code}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Country:</label>
                <select name='country' placeholder='Enter Country'
                    value={values.country}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.country && touched.country ? 'border-red-600': '')}
                    onChange={(e) => {
                        handleChange(e); 
                        getStates(e.target.value);
                        getCities(e.target.value);
                    }}
                    onBlur={handleBlur}
                >
                    <option value='' >Country</option>
                    {
                       countries?.map((country , index) => (
                            <option key={index} value={country.isoCode} >{country.name}</option>
                       ))
                    }
                </select>
                {touched.country && errors.country ? <p className='text-red-500 pl-2 italic'>{errors.country}</p> : null}
            </div>
            <div className='w-full'>
                <label>State:</label>
                <select name='state' placeholder='Enter State'
                    value={values.state}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.state && touched.state ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value=''>State</option>
                    {
                       states?.map((state , index) => (
                            <option key={index} value={state.isoCode} >{state.name}</option>
                       ))
                    }
                </select>
                {touched.state && errors.state ? <p className='text-red-500 pl-2 italic'>{errors.state}</p> : null}
            </div>
            <div className='w-full'>
                <label>City:</label>
                <select name='city' placeholder='Enter City' 
                    value={values.city}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.city && touched.city ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value=''>City</option>
                    {
                       cities?.map((city , index) => (
                            <option key={index} value={city.name} >{city.name}</option>
                       ))
                    }
                </select>
                {touched.city && errors.city ? <p className='text-red-500 pl-2 italic'>{errors.city}</p> : null}
            </div>
        </div>

        <h1 className='font-bold text-lg text-slate-500 py-2 border-t mt-5'>Package Details:</h1>

        <div className='flex justify-between gap-5 mt-3'>
            <div className='w-full'>
                <label>Package:</label>
                <select name='package_id'
                 value={values.package_id}
                 className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.package_id && touched.package_id ? 'border-red-600': '')}
                 onChange={(e) => {
                    handleChange(e)
                    getMemberPackage(e.target.value)
                }}
                onBlur={handleBlur}
                 >
                    <option value=''>Select Package</option>
                    {
                        packages?.map((pckg , index)=>(
                            <option key={index} value={pckg._id}>{pckg.title}</option>
                        ))
                    }
                </select>
                {(touched.package_id && errors.package_id) ? <p className='text-red-500 pl-2 italic'>{errors.package_id}</p> : null}
            </div>
        </div>

        <h1 className='font-bold text-lg text-slate-500 py-2 border-t mt-5'>Personal Trainer Details:</h1>
        <div className='mt-3'>
                <label>Do you want a Personal Trainer?</label>
            {/* <div className='flex justify-center gap-3 mt-5'>
                <buttom className='bg-green-500 text-white font-medium px-6 py-1 rounded-md hover:cursor-pointer hover:bg-green-600' onClick>Yes</buttom>
                <buttom className='bg-red-500 text-white font-medium px-6 py-1 rounded-md hover:cursor-pointer hover:bg-red-600'>No</buttom>
            </div> */}
            <div className='ml-3'>
                <div>
                    <input type='checkbox' onChange={()=> setFieldValue("is_trainer" , !values.is_trainer)} checked={values.is_trainer} /> Yes
                </div>
                <div>
                    <input type='checkbox' onChange={()=> setFieldValue("is_trainer" , !values.is_trainer)} checked={!values.is_trainer} /> No
                </div>
            </div>
        </div>

        {
            values.is_trainer && (
                <div className='flex justify-between gap-5 mt-5'>
                    <div className='w-full'>
                        <label>Personal Trainer:</label>
                        <select name='trainer_id'
                        value={values.trainer_id}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.trainer_id && touched.trainer_id ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        >
                            <option value=''>Select Personal Trainer</option>
                            {
                                trainers?.map((trainer , index)=>(
                                    <option key={index} value={trainer._id}>{trainer.name}</option>
                                ))
                            }
                        </select>
                        {(touched.trainer_id && errors.trainer_id) ? <p className='text-red-500 pl-2 italic'>{errors.trainer_id}</p> : null}
                    </div>
                </div>
            )
        }

        <h1 className='font-bold text-lg text-slate-500 py-2 border-t mt-5'>Member Credentials:</h1>

        <div className='flex justify-between gap-5 mt-3'>
            <div className='w-full'>
                <label>Email:</label>
                <input type='email' name='email' placeholder='Enter Email' 
                    value={values.email}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.email && touched.email ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.email && errors.email ? <p className='text-red-500 pl-2 italic'>{errors.email}</p> : null}
            </div>
            {
                role !== 'ClubAdmin' ? (
                <div className='w-full'>
                    <label>Location:</label>
                    <select name='location_id'
                    value={values.location_id}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.location_id && touched.location_id ? 'border-red-600': '')}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    >
                        <option value=''>Select Location</option>
                        {
                            locations?.map((location , index)=>(
                                <option key={index} value={location._id}>{location.name}</option>
                            ))
                        }
                    </select>
                    {(touched.location_id && errors.location_id) ? <p className='text-red-500 pl-2 italic'>{errors.location_id}</p> : null}
                </div>
                ) : (
                    <input type='hidden' name='location_id' value={values.location_id}/>
                )
            }
            
        </div>
        {
            isAddModalOpen ? (
                <div className='flex justify-between gap-5 mt-5'>
                    <div className='w-full'>
                        <label>Password:</label>
                        <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password'
                            value={values.password}
                            className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.password && touched.password ? 'border-red-600': '')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <button type='button' className='w-full flex justify-end pr-10 -mt-7 text-slate-500'
                        onClick={()=>setShowPassword(!showPassword)}
                        >
                        {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}/>}
                        </button>
                        {touched.password && errors.password ? <p className='text-red-500 pl-2 mt-3 italic'>{errors.password}</p> : null}
                    </div>
                </div>
            ) : null
        }
        <div className="flex justify-end items-center pt-3 mt-3 border-t">
            
            <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>
                <div className='flex items-center gap-2'>
                Submit
                {
                loading ? 
                <ThemeProvider theme={theme}><div className=''><CircularProgress size={20}/></div></ThemeProvider> : null
                }
                </div>
            </button>
        </div>
    </form>
  </CustomModal>
  
  <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
    <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Member</h1>
    <div className='m-5'>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Name:</h1>
            <span className='text-xl'>{member.name}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Email:</h1>
            <span className='text-xl'>{member.email}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Address:</h1>
            <span className='text-xl'>{member.address}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Phone:</h1>
            <span className='text-xl'>{member.phone}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Postal Code:</h1>
            <span className='text-xl'>{member.postal_code}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Location:</h1>
            <span className='text-xl'>{member.location_id}</span>
        </div>
    </div>
    
  </CustomModal>

  <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
    <div className='m-5'>
        <h2 className="text-xl font-medium">Delete Member?</h2>
        <p className="text-slate-500 mt-3">Are you sure you want to delete this member?</p>
        <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteMember}>Delete</button>
    </div>
  </CustomModal>

  <CustomModal isModal={isRestoreModalOpen} handleModal={toggleRestoreModal} size='md'>
    <div className='m-5'>
        <h2 className="text-xl font-medium">Restore Member?</h2>
        <p className="text-slate-500 mt-3">Are you sure you want to restore this member?</p>
        <button className='bg-green-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-green-700 mt-5 float-right mb-5' onClick={restoreMember}>Restore</button>
    </div>
  </CustomModal>
{
    isPopupOpen && (
      <Popup 
      onClose={() => setIsPopupOpen(false)}
      alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
      message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
      />
    )
  }

      
    </>
  )
}




export default Members
