import React from 'react'

import logo from '../icons/gymx-final-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


function Footer() {
  return (
    <>
    <footer>
      <div className='bg-[#F7931E] pt-3'>
          <div className="bg-[#022A3A]">
        <div className="flex justify-between px-20 py-5 items-center">
        
          <div>
            <img src={logo} alt="GymXS Logo" className="w-40 h-auto" />
          </div>

          
          <div className="space-y-2 text-sm px-16 text-white">
            <p className="flex items-center">
              <FontAwesomeIcon icon={faPhone} className="text-gray-400 mr-2" />
              Phone: +971 56 8717517
            </p>
            <p className="flex items-center">
              <FontAwesomeIcon icon={faEnvelope} className="text-gray-400 mr-2" />
              Email: 
              <a href="mailto:info@gymxs.net" className="text-[#3c8dbc] ml-1">info@gymxs.net</a>
            </p>
          </div>
        </div>
      </div>
      </div>
          
          <div className='bg-black flex justify-between px-20'>
          <p className="text-sm text-white">© 2016 - All Rights Reserved</p>
          <nav className=" space-x-2 text-sm text-white">
            <a href="#about" >Home/</a>
            <a href="#about" >About Us/</a>
            <a href="#pricing" >Product Pricing/</a>
            <a href="#contact" >Contact Us/</a>
            <a href="#policy" >Privacy Policy/</a>
          <  a href="#about" >Terms&Conditions/</a>
          <  a href="#about" >Delivery Policy/</a>
          <  a href="#about" >Refund&Cancellation Policy</a>
          </nav>
          </div>

         
        
      </footer>
    </>
  )
}

export default Footer