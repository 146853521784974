import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";


export function createExcelAndDownload(reportName, data, customHeadings) {
    // Step 1: Apply custom headings
    const formattedData = data.length
        ? data.map((item) => {
            const row = {};
            customHeadings.forEach(({ key, label }) => {
                const value = key.split('.').reduce((acc, part) => (acc ? acc[part] : null), item);
                row[label] = value ?? ""; // Default to empty string if null/undefined
            });
            return row;
        })
        : [Object.fromEntries(customHeadings.map(({ label }) => [label, ""]))]; // Create an empty row for headers

    // Step 2: Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Step 3: Define column widths
    const columnWidths = customHeadings.map(({ width }) => ({ width: width || 20 })); // Default width: 20
    worksheet["!cols"] = columnWidths;

    // Step 4: Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Step 5: Generate Excel file buffer as a Blob
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Step 6: Trigger the download using saveAs
    saveAs(blob, `${reportName}.xlsx`);
}

export const filterMembers = (members , filter) => {

    const [[key, value]] = Object.entries(filter);
    let filteredMembers = [];
     switch (key) {
            case "date":
                 filteredMembers = members.filter(
                    member => new Date(member.created_at).toISOString().split('T')[0] === value
                );
                break;
            case "month":
                filteredMembers = members.filter((member) => {
                    const memberMonth = new Date(member.created_at).toLocaleString("en-US", {
                      month: "short",
                    });
                    return memberMonth.toLowerCase() === value.toLowerCase();
                  });
                break;
            case "gender":
                filteredMembers = members.filter(
                    (member) => member.gender === value
                  );
                break;
            case "package_id":
                filteredMembers = members.filter(
                    (member) => member.package_id === value
                  );
                break;
            default:
                 filteredMembers = members
                break;
        }
    return filteredMembers
}


export  function Report(members , filter) {
    const customHeadings = [
        { key: "member_id", label: "Membership ID" },
        { key: "name", label: "Full Name" },
        { key: "email", label: "Email" , width: 25 },
        { key: "phone", label: "Phone Number" },
        { key: "gender", label: "Gender" },
        { key: "is_active", label: "Membership Status" },
        { key: "package.title", label: "Membership Package" },
        { key: "created_at", label: "Registration Date" , width: 25  },
        { key: "subscription.start_date", label: "Subscription Start Date" , width: 25  },
        { key: "subscription.end_date", label: "Subscription End Date" , width: 25  },
    ];
    createExcelAndDownload("All_Members_Report", members, customHeadings);
}