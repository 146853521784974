import React from 'react'

const RenderFiltersType = ({ filterType, handleFilterChange }) => {
    const handleRadioChange = (value) => () => handleFilterChange(value);

    return (
        <div className="text-xs">
            <div className="flex gap-2">
                {['date', 'month', 'gender', 'package_id'].map((type) => (
                    <div className="flex items-center gap-1" key={type}>
                        <input
                            type="radio"
                            name="filter"
                            value={type}
                            checked={filterType === type}
                            onChange={handleRadioChange(type)}
                        />
                        <h1 className="text-sm">Filter By {type.charAt(0).toUpperCase() + type.slice(1)}</h1>
                    </div>
                ))}
                <div className="flex items-center gap-1">
                    <input
                        type="radio"
                        name="filter"
                        value=""
                        checked={filterType === ''}
                        onChange={handleRadioChange('')}
                    />
                    <h1 className="text-sm">No Filter</h1>
                </div>
            </div>
        </div>
    );
};


export default RenderFiltersType