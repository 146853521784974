import { Box, Checkbox, CircularProgress, createTheme, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, ThemeProvider } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { personalTrainerSchema } from '../../../schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import CustomModal from '../../../base-components/CustomModal';
import Popup from '../../../base-components/Popup';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const theme = createTheme({
    palette: {
      primary: {
        main: '#033b51'
      },
    },
  });
function PersonalTrainers() {
    const navigate = useNavigate()
    const [trainers , setTrainers] = useState([])
    const [facilities , setFacilities] = useState([])
    const [trainer , setTrainer] = useState({})
    const [trainerToDelete , setTrainerToDelete] = useState('')
    const [trainerToUpdate , setTrainerToUpdate] = useState('')
    const [isAddModalOpen , setIsAddModalOpen] = useState(false)
    const [isViewModalOpen , setIsViewModalOpen] = useState(false)
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page , setPage ] = useState(0)
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [loading , setLoading] = useState(false)
    const [role , setRole] = useState('')
    const [locations , setLocations] = useState([])
    const [location , setLocation] = useState()
    const [location_id , setLocationId] = useState('')
    const [company_id , setCompanyID] = useState('')
    const [showPassword , setShowPassword] = useState(false)
    
  
    useEffect(()=> {
        const sessionStr = sessionStorage.getItem('user-info');
        if(sessionStr){
          const session = JSON.parse(sessionStr);
          setRole(session.user.role)
          setCompanyID(session.user.company_id)
          setLocationId(session.user.location_id)
        }
         else {
          navigate('/login');
        }
  },[])

    useEffect(() => {
        if(role) {
            setLoading(true)
            getTrainers()
            getLocations()
            setTimeout(()=> {
            setLoading(false)
            } , 1000)
        }
}, [role]);

    async function getLocations () {
        if(!role) return;

        let endpoint = '';
        console.log(role);
        if (role === 'SuperAdmin') {
            endpoint = 'locations/getLocations';
        } else if (role === 'Admin') {
            endpoint = `locations/getLocationsByCompanyID/${company_id}`;
        }
        
        await fetch(BASE_URL + endpoint)
            .then((response) => response.json())
            .then((data) => {
                setLocations(data.locations)
            })
            .catch((error) => {
                console.log(error)
            } );
    }
    async function getLocationId (id) {
        await fetch(BASE_URL + `locations/getLocationById/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setLocationId(data.location._id)
            setLocation(data.location)
        })
        .catch((error) => console.log(error));
    }
  
    async function getTrainers () {
          if(!role) return;
          setLoading(true)
          let endpoint = '';
          console.log(role);
          if (role === 'SuperAdmin') {
              endpoint = `trainers/get-trainers`;
          } else if (role === 'Admin' ) {
              endpoint = `trainers/get-trainers-by-company/${company_id}`;
          } else if(role === 'ClubAdmin') {
            endpoint = `trainers/get-trainers-by-location/${location_id}`;
          }
        await fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setTrainers(data.trainers)
            setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
      });
    }
  
    async function getTrainerByID (id) {
       await fetch(BASE_URL + `trainers/get-trainer/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setTrainer(data.trainer)
            setValues(data.trainer);
        })
        .catch((error) => console.log(error));
    }

    const handleAddTrainer = async (values, actions) => {

        values.company_id = company_id
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            };
    
            try {
                const response = await fetch(BASE_URL + `trainers/create-trainer`, requestOptions);
    
                console.log('Response status:', response.status);
                if (!response.ok) {
                    const responseData = await response.json();
                    console.error('Response not ok:', responseData);
                    setIsFailureAlert(true)
                    setFailureMessage(responseData.message)
                    setIsPopupOpen(true)
                    setTimeout(() => {
                        setIsPopupOpen(false)
                    }, 5000)
                } else {
                    const responseData = await response.json();
                    console.log('Response data:', responseData);
                    setIsAddModalOpen(false)
                    actions.resetForm()
                    getTrainers()
                    setIsSuccessAlert(true)
                    setSuccessMessage('Trainer added Successfully')
                    setIsPopupOpen(true)
                    setTimeout(() => {
                        setIsPopupOpen(false)
                    } , 5000)
                }
            } catch (error) {
                console.error('Caught error:', error);
                setIsFailureAlert(true) 
                setFailureMessage('Failed to add Trainer')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                } , 5000)
            } finally {
                // setLoading(false);
            }
    
    }
  
    const handleUpdateTrainer = async (values, actions) => {
        values.company_id = company_id
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };
  
        try {
            const response = await fetch(BASE_URL + `trainers/update-trainer/${trainerToUpdate}`, requestOptions);
  
            console.log('Response status:', response.status);
            if (!response.ok) {
                console.error('Response not ok:', response);
                setIsFailureAlert(true)
                setFailureMessage('Failed to Update Trainer')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            } else {
                const responseData = await response.json();
                console.log('Response data:', responseData);
                actions.resetForm()
                setIsUpdateModalOpen(false)
                getTrainers()
                setIsSuccessAlert(true)
                setSuccessMessage('Trainer Updated Successfully')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            }
        } catch (error) {
            console.error('Caught error:', error);
            setIsFailureAlert(true)
            setFailureMessage('Failed to Update Trainer')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        } finally {
            // setLoading(false);
        }
  
  }
  
    function deleteTrainer () {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        };
        fetch(BASE_URL + `trainers/delete-trainer/${trainerToDelete}` , requestOptions)
        .then((response) => response.json())
        .then((data) => {
            getTrainers()
            setIsDeleteModalOpen(false)
            setIsSuccessAlert(true)
            setSuccessMessage('Trainer deleted Successfully')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        })
        .catch((error) => {
            console.log(error)
            setIsFailureAlert(true)
            setFailureMessage('Failed to delete Trainer')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        });
    }
  
  
    function toggleAddModal() {
        setIsAddModalOpen(!isAddModalOpen)
        resetForm()
    }
    function toggleViewModal() {
        setIsViewModalOpen(!isViewModalOpen)
        resetForm()
    }
  
    function toggleUpdateModal() {
        setIsUpdateModalOpen(!isUpdateModalOpen)
        resetForm()
    }
    function toggleDeleteModal() {
        setIsDeleteModalOpen(!isDeleteModalOpen)
    }
  
    function showAddModal(id) {
        setIsAddModalOpen(true);
    }
    function showViewModal(id) {
        getTrainerByID(id);
        setIsViewModalOpen(true);
    }
    function showUpdateModal(id) {
        getTrainerByID(id);
        setTrainerToUpdate(id);
        setIsUpdateModalOpen(true);
    }
    function showDeleteModal(id) {
        setTrainerToDelete(id);
        setIsDeleteModalOpen(true);
    }
  
    const paginatedTrainers = !trainers || trainers.length === 0 ? [] :
    rowsPerPage === -1
    ? trainers // Show all if "All" option is selected
    : trainers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
  
      const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm , setFieldValue} = useFormik({
            enableReinitialize: true, 
            initialValues: {
            name: '',
            email: '',
            password: '',
            phone_number: '',
            address: '',
            specialization:'',
            experience_years: '',
            certifications: '',
            monthly_rate: '',
            availability: [],
            hire_date: '',
            employment_status: '',
            is_active: '',
            added_by:'',
            updated_at: '',
            location_id: '',
            company_id: '',
        },
        validationSchema: personalTrainerSchema,
        onSubmit: (values, actions) => {
            console.log('Formik Errors:', errors); 
            if(isAddModalOpen){
                handleAddTrainer(values, actions); // Call the external function
            } else if(isUpdateModalOpen) {
                handleUpdateTrainer(values, actions);
            }
        },
    })
  return (
    <>
        <div className='flex items-center justify-between m-5'>
            <div className='font-bold text-2xl'>Personal Trainers</div>
            {
                role === 'SuperAdmin' || role === 'Admin' ? (
                    <button className='bg-[#033b51] text-white font-bold p-2 rounded-lg ' onClick={showAddModal}>Add Trainer</button>
                ) : null
            }
        </div>
        {
          loading ? <ThemeProvider theme={theme}><div className='flex justify-center items-center mt-20'><CircularProgress/></div></ThemeProvider>
          :(
            trainers && trainers.length !== 0 ? (     
            <Box className="border rounded-lg m-5 animate-fadeIn overflow-auto ">
                <Table>
                  <TableHead>
                      <TableRow >
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Experience</TableCell>
                          <TableCell>Hire Date</TableCell>
                          <TableCell>Actions</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      paginatedTrainers?.map((trainer , index) => (
                        <TableRow hover key={index}>
                            <TableCell>{trainer.name}</TableCell>
                            <TableCell>{trainer.email}</TableCell>
                            <TableCell>{trainer.phone_number}</TableCell>
                            <TableCell>{trainer.experience_years} Years</TableCell>
                            <TableCell>{trainer.hire_date.toString().split('T')[0]}</TableCell>
                            <TableCell>
                            <div className='flex gap-2'>
                                <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                                showViewModal(trainer._id);
                                }}>
                                    <FontAwesomeIcon icon={faEye}/> View 
                                </div>
                                <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                                showUpdateModal(trainer._id);
                                }}>
                                    <FontAwesomeIcon icon={faPen}/> Update 
                                </div>
                                <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-600'
                                onClick={()=>{
                                showDeleteModal(trainer._id);
                                }}
                                >
                                    <FontAwesomeIcon icon={faTrash}/> Delete 
                                </div>
                            </div>
                            </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                      <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={12}
                          count= {trainers.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          slotProps={{
                              select: {
                              inputProps: {
                                  'aria-label': 'rows per page',
                              },
                              native: true,
                              },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                  </TableFooter>
              </Table>
            </Box>
            ) : (
              <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10'>
                <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
              </div>
            )
          )
          }
  
    <CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
        if(isAddModalOpen){
            toggleAddModal();
        } else if(isUpdateModalOpen) {
            toggleUpdateModal();
        }
    }} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
            {
                isAddModalOpen ? 'Add Trainer' : 'Update Trainer' 
            }
        </h1>
        <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
            <div>
                <input type='hidden' value={values.company_id}/>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Name:</label>
                    <input type='text' name='name' placeholder='Enter Name' 
                        value={values.name}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.name && touched.name ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p className='text-red-500 pl-2 italic'>{errors.name}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Email:</label>
                    <input type='email' name='email' placeholder='Enter Email' 
                        value={values.email}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.email && touched.email ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? <p className='text-red-500 pl-2 italic'>{errors.email}</p> : null}
                </div>
            </div>
             {
                isAddModalOpen ? (
                    <div className='flex justify-between gap-5 mt-5'>
                        <div className='w-full'>
                            <label>Password:</label>
                            <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password'
                                value={values.password}
                                className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.password && touched.password ? 'border-red-600': '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <button type='button' className='w-full flex justify-end pr-10 -mt-7 text-slate-500'
                            onClick={()=>setShowPassword(!showPassword)}
                            >
                            {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}/>}
                            </button>
                            {touched.password && errors.password ? <p className='text-red-500 pl-2 mt-3 italic'>{errors.password}</p> : null}
                        </div>
                    </div>
                ) : null
            }
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Phone:</label>
                    <input type='number' name='phone_number' placeholder='Enter Phone' 
                        value={values.phone_number}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.phone_number && touched.phone_number ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.phone_number && errors.phone_number ? <p className='text-red-500 pl-2 italic'>{errors.phone_number}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Experience :</label>
                    <input type='number' name='experience_years' placeholder='Enter Experience in Years' 
                        value={values.experience_years}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.experience_years && touched.experience_years ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.experience_years && errors.experience_years ? <p className='text-red-500 pl-2 italic'>{errors.experience_years}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Hire Date:</label>
                    <input type='date' name='hire_date' placeholder='Hire Date' 
                        value={values.hire_date.toString().split('T')[0]}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.hire_date && touched.hire_date ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.hire_date && errors.hire_date ? <p className='text-red-500 pl-2 italic'>{errors.hire_date}</p> : null}
                </div>
                <div className='w-full'>
                    <FormControl fullWidth error={touched.availability && Boolean(errors.availability)}>
                        <label>Available Days:</label>
                        <Select
                        name='availability'
                        label='Available Days'
                        value={values.availability}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiple
                        className='w-full max-w-[320px] h-10 bg-slate-100 w-full p-2 rounded-xl outline-0'
                        input={<OutlinedInput label='Available Days' />}
                        >
                        <MenuItem value='Mon'>Monday</MenuItem>
                        <MenuItem value='Tue'>Tuesday</MenuItem>
                        <MenuItem value='Wed'>Wednesday</MenuItem>
                        <MenuItem value='Thurs'>Thursday</MenuItem>
                        <MenuItem value='Fri'>Friday</MenuItem>
                        <MenuItem value='Sat'>Satureday</MenuItem>
                        <MenuItem value='Sun'>Sunday</MenuItem>
                        </Select>
                        {touched.availability && errors.availability && (
                        <FormHelperText>{errors.availability}</FormHelperText>
                        )}
                    </FormControl>
                </div>
            </div>

            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Monthly Fee:</label>
                    <input type='number' name='monthly_rate' placeholder='Enter Monthly Fee' 
                        value={values.monthly_rate}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.monthly_rate && touched.monthly_rate ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.monthly_rate && errors.monthly_rate ? <p className='text-red-500 pl-2 italic'>{errors.monthly_rate}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Employment Status :</label>
                    <select name='employment_status' placeholder='Enter Employment Status'
                        value={values.employment_status}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.employment_status && touched.employment_status ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option>Select Employment Status</option>
                        <option value='part-time'>Part Time</option>
                        <option value='full-time'>Full Time</option>
                    </select>
                    {touched.employment_status && errors.employment_status ? <p className='text-red-500 pl-2 italic'>{errors.employment_status}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                {
                    role === 'SuperAdmin' || role === 'Admin' ? (
                        <div className='w-full'>
                            <label>Location:</label>
                            <select name='location_id' placeholder='Enter Location'
                                value={values.location_id}
                                className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.location_id && touched.location_id ? 'border-red-600': '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option>Locations</option>
                                {
                                    role === 'superAdmin' ? (
                                        <option value={location?._id}>{location?.name}</option>
                                    ) : (
                                        locations?.map((location , index) => (
                                            <option key={index} value={location._id}>{location.name}</option>
                                        ))
                                    )
                                    
                                }
                            </select>
                            {touched.location_id && errors.location_id ? <p className='text-red-500 pl-2 italic'>{errors.location_id}</p> : null}
                        </div>
                    ) :null
                }
                <div className='w-full'>
                    <label>Address :</label>
                    <input type='text' name='address' placeholder='Enter Address' 
                        value={values.address}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.address && touched.address ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.address && errors.address ? <p className='text-red-500 pl-2 italic'>{errors.address}</p> : null}
                </div>
            </div>
            <div className="flex justify-end items-center pt-3 mt-3 border-t">
                <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>Submit</button>
            </div>
        </form>
      </CustomModal>
      
      <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Trainer</h1>
        <div className='m-5'>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Name:</h1>
                <span className='text-xl'>{trainer.name}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Email:</h1>
                <span className='text-xl'>{trainer.email}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Experience:</h1>
                <span className='text-xl'>{trainer.experience_years}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Fee:</h1>
                <span className='text-xl'>{trainer.monthly_rate}</span>
            </div>
        </div>
        
      </CustomModal>
      {
        isPopupOpen && (
            <Popup 
             onClose={() => setIsPopupOpen(false)}
             alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
             message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
             />
                
        )
      }
      
      <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
        <div className='m-5'>
            <h2 className="text-xl font-medium">Delete Trainer?</h2>
            <p className="text-slate-500 mt-3">Are you sure you want to delete this Trainer?</p>
            <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteTrainer}>Delete</button>
        </div>
      </CustomModal>
    </>
  )
}

export default PersonalTrainers